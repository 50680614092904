import { Component, OnInit } from '@angular/core';
import {BillingType} from "src/app/classes/common/organization";
import {BillingLocation} from "src/app/classes/common/organization";
import {Utility} from "src/app/classes/common/utility";
import {ViewService} from "src/app/services/common/view.service";
import {ChangeDetectorRef} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "src/app/services/common/data.service";
import {ApiService} from "src/app/services/common/api.service";
import {Input} from "@angular/core";
import {AppComponent} from "src/app/app.component";
import {PopoverFlagComponent} from "src/app/components/desktop/popover-flag/popover-flag.component";
import {NavController} from "@ionic/angular";
import {PopoverController} from "@ionic/angular";
import {Organization} from "src/app/classes/common/organization";
import {Output} from "@angular/core";
import {EventEmitter} from "@angular/core";

@Component({
  selector: 'app-user-personal-data',
  templateUrl: './user-personal-data.component.html',
  styleUrls: ['./user-personal-data.component.scss'],
})
export class UserPersonalDataComponent implements OnInit {
  @Input() fromActivation: boolean;
  @Input() billingType: BillingType; //2
  @Input() billingLocation: BillingLocation; //1
  @Input() appComponent: AppComponent;

  @Output() personalDataUpdated = new EventEmitter<any>()


  billingTypeError: string;
  billingLocationError: string;
  paymentMethod: string = 'card';

  lang: any;

  name: string;
  nameError: string;
  surname: string;
  surnameError: string;
  address: string;
  addressError: string;
  zip: string;
  zipError: string;
  // useful when we want the msg for the alert != msg for the input
  zipErrorAlert: string;
  city: string;
  cityError: string;
  province: string;
  provinceCode: string;
  provinceError: string;
  // email: string;
  emailError: string;
  phone: string;
  companyName: string;
  companyNameError: string;
  vat: string;
  vatError: string;
  // useful when we want the msg for the alert != msg for the input
  vatErrorAlert: string;
  fiscalCode: string;
  fiscalCodeError: string;
  // useful when we want the msg for the alert != msg for the input
  fiscalCodeErrorAlert: string;
  nation: string;
  nationCode: string;
  nationError: string;
  pec: string;
  pecError: string;
  sdi: string;
  sdiError: string;

  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private view: ViewService,
    private data: DataService,
    private api: ApiService,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
  ) { }

  ngOnInit() {
    this.translate.get('USER_DATA').subscribe((lang: any) => {
      this.lang = lang;
    });
    if(this.billingLocation === 1){
      this.nation = 'Italy';
      this.nationCode = 'IT';
      if(!this.provinceCode) this.province = undefined;
    }else{
      // a part of this code was taken from the constructor and
      // commented there
      let foundNationNotEligible: boolean;
      if (this.nationCode) {
        let foundNation: any = DataService.generalConfig.getNation(this.nationCode);
        if (foundNation) {
          switch (this.billingLocation) {
            // europa
            case 2:
              // we don't want ITaly to be among eu nations
              if (foundNation.eu && foundNation.code !== "IT") {
                this.nation = foundNation.name;
                this.nationCode = foundNation.code;
              } else {
                foundNationNotEligible = true;
              }
              break;
            // extra-europa
            case 3:
              if (foundNation.eu) {
                foundNationNotEligible = true;
              } else {
                this.nation = foundNation.name;
                this.nationCode = foundNation.code;
              }
              break;
          }
        }
      }
      if (!this.nationCode || foundNationNotEligible) {
        // no loose ends
        this.nation = undefined;
        this.nationCode = undefined;
      }

      // this part was left as is
      if(this.provinceCode){
        this.province = undefined;
        this.provinceCode = undefined;
      }
    }

  }

  // Effettua la verifica sul form
  async checkFormError(
    input: string,
    noTimeout?: boolean,
    lightCheck?: boolean,
  ) {
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'billingType':
        if(!this.billingType){
          this.billingTypeError = this.lang.necessaria_tipologia;
          ViewService.updateView.next(undefined);
          return;
        }
        this.billingTypeError = undefined;
        break;
      case 'billingLocation':
        if(!this.billingLocation){
          this.billingLocationError = this.lang.necessaria_location;
          ViewService.updateView.next(undefined);
          return;
        }
        this.billingLocationError = undefined;
        break;
      case 'name':
        if(!this.name){
          this.nameError = this.lang.necessario_nome;
          ViewService.updateView.next(undefined);
          return;
        }
        this.nameError = undefined;
        break;
      case 'surname':
        if(!this.surname){
          this.surnameError = this.lang.necessario_cognome;
          ViewService.updateView.next(undefined);
          return;
        }
        this.surnameError = undefined;
        break;
      case 'address':
        if(!this.address){
          this.addressError = this.lang.necessario_indirizzo;
          ViewService.updateView.next(undefined);
          return;
        }
        this.addressError = undefined;
        break;
      case 'zip':
        this.zipErrorAlert = null
        if(!this.zip){
          this.zipError = this.lang.necessario_cap;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.zipError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        if(this.billingLocation === 1 && !this.appComponent.isForcedAuth){ // Api verifica zip code solo se italia
          try {
            const res = await this.api.patchCheck_zip(
              this.data.selOrganization.me._id,
              {
                zip: this.zip,
              },
            );
            console.log(`res`, res);
            if (!res.isCorrect) {
              this.zipError = this.lang.zip_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`);
            this.zipError = this.lang.check_api_fail;
            this.zipErrorAlert = this.lang.zip_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }
        this.zipError = undefined;
        break;
      case 'city':
        if(!this.city){
          this.cityError = this.lang.necessaria_citta;
          ViewService.updateView.next(undefined);
          return;
        }
        this.cityError = undefined;
        break;
      case 'province':
        if(!this.province){
          this.provinceError = this.lang.necessario_provincia;
          ViewService.updateView.next(undefined);
          return;
        }
        this.provinceError = undefined;
        break;
      case 'companyName':
        if(!this.companyName){
          this.companyNameError = this.lang.necessario_nome_azienda;
          ViewService.updateView.next(undefined);
          return;
        }
        this.companyNameError = undefined;
        break;
      case 'vat':
        this.vatErrorAlert = null
        if(!this.vat){
          this.vatError = this.lang.necessario_piva;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.vatError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        //se billingLocation è italia allora procedo con il check
        if(this.billingLocation == 1 && !this.appComponent.isForcedAuth ){
          try {
            const res = await this.api.patchCheck_partita_iva(
              this.data.selOrganization.me._id,
              {
                partita_iva: this.vat,
              },
            )
            console.log(`res`, res)
            if (!res.isCorrect) {
              this.vatError = this.lang.partita_iva_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`, )
            this.vatError = this.lang.check_api_fail;
            this.vatErrorAlert = this.lang.partita_iva_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }

        this.vatError = undefined;
        break;
      case 'fiscalCode':
        this.fiscalCodeErrorAlert = null
        if(!this.fiscalCode){
          this.fiscalCodeError = this.lang.necessario_codice_fiscale;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.fiscalCodeError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        if(this.billingLocation === 1 && !this.appComponent.isForcedAuth){ // Controllo solo se italia
          try {
            const res = await this.api.patchCheck_codice_fiscale(
              this.data.selOrganization.me._id,
              {
                codice_fiscale: this.fiscalCode,
              },
            )
            console.log(`res`, res)
            if (!res.isCorrect) {
              this.fiscalCodeError = this.lang.codice_fiscale_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`, )
            this.fiscalCodeError = this.lang.check_api_fail;
            this.fiscalCodeError = this.lang.codice_fiscale_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }


        this.fiscalCodeError = undefined;
        break;
      case 'nation':
        if(!this.nation){
          this.nationError = this.lang.necessaria_nazione;
          ViewService.updateView.next(undefined);
          return;
        }
        this.nationError = undefined;
        break;
      case 'pec':
        if(!this.pec){
          this.pecError = this.lang.necessaria_pec;
          ViewService.updateView.next(undefined);
          return;
        }
        if(!Utility.validateEmail(this.pec)){
          this.pecError = this.lang.pec_invalida;
          ViewService.updateView.next(undefined);
          return;
        }
        this.pecError = undefined;
        break;
      case 'sdi':
        if(!this.sdi){
          this.sdiError = this.lang.necessario_sdi;
          ViewService.updateView.next(undefined);
          return;
        }
        this.sdiError = undefined;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  openSelect(event: any, type: string){
    switch (type) {
      case 'provinces':
        this.popoverProvinces(event);
        break;
      case 'nation':
        if(this.billingLocation !== 1){
          this.popoverNations(event);
        }
        break;
    }
  }

  // Apre scelta provincia
  async popoverProvinces(event: any) {
    let options: any[] = [];
    for(let prov of DataService.generalConfig.italy_province){
      options.push({
        id: prov.sigla,
        lbl: prov.nome,
        selected: this.province === prov.sigla,
      });
    }
    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: true
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.province = data.data.lbl;
        this.provinceCode = data.data.id;
        ViewService.updateView.next(undefined);
      }
      this.checkFormError('province');
    });
    await popover.present();
  }

  // Apre scelta nazione
  async popoverNations(event: any) {
    let options: any[] = [];

    let nationEligible: boolean;
    for (let nat of DataService.generalConfig.nations) {
      nationEligible = false;

      switch (this.billingLocation) {
        // europa
        case 2:
          // we don't want ITaly to be among eu nations
          if (nat.eu && nat.code !== "IT") {
            nationEligible = true;
          }
          break;
        // extra-europa
        case 3:
          if (!nat.eu) {
            nationEligible = true;
          }
          break;
      }

      if (nationEligible) {
        options.push({
          id: nat.code,
          lbl: nat.name,
          selected: this.nation === nat.name,
        });
      }

      // ? OLD code, remove?
      // if((this.billingLocation === 2 && nat.eu) || (this.billingLocation === 3 && !nat.eu)){
      //   options.push({
      //     id: nat.code,
      //     lbl: nat.name,
      //     selected: this.nation === nat.name,
      //   });
      // }
    }

    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: true
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.nation = data.data.lbl;
        this.nationCode = data.data.id,
          ViewService.updateView.next(undefined);
      }
      this.checkFormError('nation');
    });
    await popover.present();
  }


  async save(){
    ViewService.updateView.next(undefined);
    let organization: Organization = new Organization();
    organization._id = this.data.selOrganization._id;
    organization.billing_type = this.billingType;
    organization.billing_location = this.billingLocation;
    organization.billing_companyName = this.companyName;
    organization.billing_name = this.name;
    organization.billing_surname = this.surname;
    organization.billing_address = this.address;
    organization.billing_zip = this.zip;
    organization.billing_city = this.city;
    organization.billing_province = this.provinceCode || this.province;
    organization.billing_country = this.nationCode;
    // organization.billing_mail = this.email;
    organization.billing_phone = this.phone;
    organization.billing_vat = this.vat;
    organization.billing_mailPEC = this.pec;
    organization.billing_sdiCode_fatturaElettronica = this.sdi;
    organization.billing_codiceFiscale = this.fiscalCode;

    try{
      await this.view.hideLoading()
      await this.view.showLoading(this.translate.instant('FREE_TRIAL.salvataggio_in_corso'))
      let res: any = await this.api.patchBillingUser(this.data.selOrganization.me._id, organization);
      this.data.selOrganization.setData(res);
      this.personalDataUpdated.emit({success: true})
      await this.view.hideLoading()
      if(!this.fromActivation){
        this.view.modalAlert(this.lang.successo, this.lang.salvataggio_completato, 'succ', this.lang.ok);
      }else{
        await DataService.api.getOrganization(this.data.selOrganization.me._id, this.data.selOrganization._id).then((res: any) => {
          this.data.selOrganization.setData(res);
          ViewService.updateView.next(undefined);
        }).catch((err: any) => {
          console.error(err);
        });
      }
      //success
    }catch(err){
      console.error(err);
      //error
      await this.view.hideLoading()
      this.personalDataUpdated.emit({success: true})
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.impossibile_salvare, 'warn', this.lang.ok);
    }
  }


}
