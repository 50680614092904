import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {
  ModalController,
  PopoverController
} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ViewService} from "../../../../services/common/view.service";
import {DataService} from "../../../../services/common/data.service";
import {AuthService} from "../../../../services/common/auth.service";
import {ApiService} from "../../../../services/common/api.service";
import {AppLauncher} from "@capacitor/app-launcher";
import {TicketAddPage} from "../ticket-add/ticket-add.page";
import {
  NavigationService
} from "../../../../services/common/navigation.service";
type PageTypes = 'delinquent' | 'hint' | undefined;

@Component({
  selector: 'app-payment-problems',
  templateUrl: './payment-problems.page.html',
  styleUrls: ['./payment-problems.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentProblemsPage implements OnInit {

  @Input() type: PageTypes;
  @Input() dismissible: boolean;

  lang: any;
  closeAction: any;

  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private view: ViewService,
    private modalCtrl: ModalController,
    public data: DataService,
    private auth: AuthService,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    private api: ApiService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.listenerView();
    this.translate.get('PAYMENT_PROBLEMS').subscribe((lang: any) => {
      this.lang = lang;
      ViewService.updateView.next(undefined);
    });
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  async openTickets(){
    const modal = await this.modalCtrl.create({
      component: TicketAddPage,
      componentProps: {
        isModal: true
      },
      backdropDismiss: false,
      cssClass: "modal_alarm"
    });
    return await modal.present();
  }

  async openPaymentPortal(){
    NavigationService.navigate('tabs/menu/payment', false, false);

    // this.navCtrl.navigateRoot('payment');
    this.dismiss();
  }

}
