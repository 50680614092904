// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltipv {
  position: fixed;
  background-color: black;
  border-radius: 4px;
  color: #ffffff;
  padding: 3px 6px;
  font-size: 13px;
  margin-top: 5px;
  transform: translateX(-5%);
  max-width: 150px;
}
.tooltipv::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  position: absolute;
  left: 2px;
  top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/tooltip/tooltip.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,0BAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,8BAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;AAEJ","sourcesContent":[".tooltipv {\n  position: fixed;\n  background-color: black;\n  border-radius: 4px;\n  color: #ffffff;\n  padding: 3px 6px;\n  font-size: 13px;\n  margin-top: 5px;\n  transform:translateX(-5%);\n  max-width: 150px;\n  &::before {\n    content: '';\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-bottom: 5px solid black;\n    position: absolute;\n    left: 2px;\n    top: -5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
