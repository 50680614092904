import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ViewService
} from "../../../services/common/view.service";

@Component({
  selector: 'app-small-alert',
  templateUrl: './small-alert.page.html',
  styleUrls: ['./small-alert.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallAlertPage implements OnInit {

  // componentProps
  @Input() icon: string;
  @Input() msg: string;
  @Input() title: string;
  @Input() buttons: any[];
  @Input() view: ViewService;

  // listener
  listener = {
    view: null,
  };

  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async dismiss() {
    await this.modalCtrl.dismiss();
  }

  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
    });
  }

  async toggleClick(button: any){
    await this.dismiss();
    if(button.click){
      button.click()
    }
    console.log('finito')
  }
}
