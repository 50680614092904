import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {Input} from "@angular/core";
import {
  Partecipant
} from "../../../classes/common/partecipant";
import {
  ViewService
} from "../../../services/common/view.service";
import {Output} from "@angular/core";
import {EventEmitter} from "@angular/core";
import {DataReportService} from "src/app/services/common/dataReport.service";
import {ShopService} from "src/app/services/common/shop.service";


type Layout =
  "chip" |
  "basicChip" |
  "item" |
  "basicItem" |
  "itemBody" |
  "selectItem" | "selectAll";

type Status =
  "off" |
  "on";


@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarComponent implements OnInit {

  // component args
  @Input() car: Partecipant;
  @Input() cars: Partecipant[];
  // additional css classes
  @Input() css: string;
  @Input() selectAll: boolean = false;

  @Input() layout: Layout = "item";

  // selectAll: boolean = false;
  // listener

  listener = {
    view: null,
    listenersetAllVeichles: null,
  };



  constructor(
    private cd: ChangeDetectorRef,
    public view: ViewService,
    private reportService: DataReportService
  ) {}



  ngOnInit() {
    this.listenerView();
    this.listenerSetAllVeichles();

    this.selectAll = DataReportService.allVeichles
  }


  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

    });
  }


  setItemSelected(car: Partecipant){
    car.$selected = !car.$selected

    if(DataReportService.allVeichles){
      DataReportService.setAllVeichles.next({target: false, single: true})
    }

    ViewService.updateView.next(undefined);
  }

  setAllItemsSelected(){
    if(this.cars){
      DataReportService.setAllVeichles.next({target: !DataReportService.allVeichles})
    }
    ViewService.updateView.next(undefined);
  }


  /**
   * Listener for set all veichles
   */
  listenerSetAllVeichles(){
    this.listener.listenersetAllVeichles = DataReportService.setAllVeichles$.subscribe((obj: any) => {

      DataReportService.allVeichles = obj.target
      this.selectAll = DataReportService.allVeichles;

      if(!obj.single){
        if(DataReportService.allVeichles){
          for(let key in this.cars){
            this.cars[key].$selected = true;
          }
        }else{
          for(let key in this.cars){
            this.cars[key].$selected = false;
          }
        }
      }

    });
  }




}
