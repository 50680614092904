import { Injectable } from '@angular/core';
import {PositionGps} from "../../classes/common/position-gps";
import {Partecipant} from "../../classes/common/partecipant";
import {Subject} from "rxjs";
import {DataService} from "src/app/services/common/data.service";
import {ShopService} from "src/app/services/common/shop.service";
import {ViewService} from "src/app/services/common/view.service";
import {Storage} from "@ionic/storage";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataReportService {

  startDate_date: any;
  stopDate_date: any;
  sel_vehicle: Partecipant;
  sel_vehicles: Partecipant[] = [];

  limit: number = 5000;
  summary: any;
  route: PositionGps[] = [];

  static allVeichles: boolean;

  static setAllVeichles = new Subject<any>();
  static setAllVeichles$ = DataReportService.setAllVeichles.asObservable()

  static timeoutSubject: Subject<any> = new Subject<any>();
  private timeoutSubject$ = DataReportService.timeoutSubject.asObservable()

  constructor(
    private storage: Storage,
  ) {}

  reset(){
    this.sel_vehicle = undefined;
    this.sel_vehicles = [];
    this.startDate_date = undefined;
    this.stopDate_date = undefined;
    this.route = [];
    this.route = undefined;
    this.summary = undefined;
  }

  toggleVehicles(vehicle: Partecipant){
    if(!this.findVehicles(vehicle)){
      if(!this.sel_vehicles){
        this.sel_vehicles = []
      }
      this.sel_vehicles.push(vehicle);
    }else{
      this.removeVehicles(vehicle)
    }

    this.saveStorage()
  }

  findVehicles(vehicle: Partecipant){
    if(this.sel_vehicles?.length){
      const vehicles = this.sel_vehicles.find((elem)=>{return elem._id === vehicle._id})
      if(vehicles){
        return true;
      }
      return false;
    }
  }

  removeVehicles(vehicle: Partecipant){
    if(DataReportService.allVeichles){
      DataReportService.setAllVeichles.next({target: false, single: true})
    }
    const indiceVeicolo = this.sel_vehicles.findIndex(elem => elem._id === vehicle._id);
    if (indiceVeicolo !== -1) {
      this.sel_vehicles.splice(indiceVeicolo, 1);
    }
  }

  hasVehicles(){
    return this.sel_vehicles?.length
  }

  saveStorage(){
    const selected_car: any[] = []
    for(let part of this.sel_vehicles){
      selected_car.push(part._id)
    }
    this.storage.set('last_selected_device_report', selected_car)
    ViewService.updateView.next(undefined);
  }


  static convertTimestmpForReport(timestamp: any, label: any){
    const totalSeconds = Math.floor(timestamp / 1000);

    const days = Math.floor(totalSeconds / 86400); // 86400 secondi in un giorno
    const remainingSeconds = totalSeconds % 86400;

    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;

    // Aggiungi uno zero davanti a ore, minuti e secondi se sono inferiori a 10
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;


    return `${days}${label.label_day || 'd'} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

  }

  startTimeout(ms: number): Observable<void> {
    console.log('Sono in start timeout')
    setTimeout(() => {
      console.log('Timeout')
      DataReportService.timeoutSubject.next(undefined);
      DataReportService.timeoutSubject.complete();
    }, ms);
    return DataReportService.timeoutSubject.asObservable();
  }



}
