// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  --font-size-12: 0.75rem;
  --font-size-14: 0.87rem;
  --font-size-16: 1rem;
  --font-size-20: 1.25rem;
  --font-size-24: 1.5rem;
  --font-size-36: 2.25rem;
  --spacing-small: 0.5rem;
  --spacing-normal: 1.5rem;
  --spacing-large: 4rem;
  --gray: rgba(26, 26, 26, .6);
  --gray500: #697386;
  --black: #1a1f36;
  --divider-color: var(--gray);
  --weight-medium: 500;
  --weight-bold: bold;
}

ion-select {
  min-width: 75%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/select-payment-method/select-payment-method.component.scss"],"names":[],"mappings":"AAIA;EACE,uBAAA;EACA,uBAAA;EACA,oBAAA;EACA,uBAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;EACA,wBAAA;EACA,qBAAA;EAEA,4BAAA;EACA,kBAAA;EACA,gBAAA;EAEA,4BAAA;EAEA,oBAAA;EACA,mBAAA;AANF;;AASA;EACE,cAAA;AANF","sourcesContent":["\n$smallBorder: 1px solid var(--app-mid) !important;\n$shadow-sm: 0 4px 8px rgba(var(--app-cover-rgb), .15);\n\n*{\n  --font-size-12: 0.75rem;\n  --font-size-14: 0.87rem;\n  --font-size-16: 1rem;\n  --font-size-20: 1.25rem;\n  --font-size-24: 1.5rem;\n  --font-size-36: 2.25rem;\n  --spacing-small: 0.5rem;\n  --spacing-normal: 1.5rem;\n  --spacing-large: 4rem;\n\n  --gray: rgba(26, 26, 26, .6);\n  --gray500: #697386;\n  --black: #1a1f36;\n\n  --divider-color: var(--gray);\n\n  --weight-medium: 500;\n  --weight-bold: bold;\n}\n\nion-select {\n  min-width: 75%;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
