import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "./services/common/data.service";
import {AuthService} from "./services/common/auth.service";
import {ApiService} from "./services/common/api.service";
import {ViewService} from "./services/common/view.service";
import {GoogleMapsService} from "./services/common/google-map.service";
import {
  AlertController,
  ModalController, ToastController, Platform
} from "@ionic/angular";
import {StatisticsService} from "./services/common/statistics.service";
import * as moment from 'moment';
import 'moment-timezone';
import {
  MainMapService
} from "./services/common/main-map-service";
import {Utility} from "./classes/common/utility";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import { Storage } from '@ionic/storage-angular';
import {Subject} from "rxjs";
import {NavigationService} from "./services/common/navigation.service";
import { SplashScreen } from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';
import { AppLauncher } from '@capacitor/app-launcher';
import {Device, DeviceId, DeviceInfo} from '@capacitor/device';
import {Capacitor} from '@capacitor/core';
import {App, AppInfo} from "@capacitor/app";
import {PushNotifications} from '@capacitor/push-notifications';
import {Location} from "@angular/common";
import {PaymentProblemsPage} from "./pages/mobile/common/payment-problems/payment-problems.page";
import {environment} from '../environments/environment'

declare let firebase: any;
declare let google: any;
declare let OverlappingMarkerSpiderfier: any;
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app-mobile.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppMobileComponent {

  static iconsGreen: any = {
    0: {min: 337.5, max: 22.5, url: 'assets/img/markers/marker-green-0.png', url_in1_1: 'assets/img/markers/marker-green-0-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-0-in1_0.png'},
    45: {min: 22.5, max: 67.5, url: 'assets/img/markers/marker-green-45.png', url_in1_1: 'assets/img/markers/marker-green-45-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-45-in1_0.png'},
    90: {min: 67.5, max: 112.5, url: 'assets/img/markers/marker-green-90.png', url_in1_1: 'assets/img/markers/marker-green-90-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-90-in1_0.png'},
    135: {min: 112.5, max: 157.5, url: 'assets/img/markers/marker-green-135.png', url_in1_1: 'assets/img/markers/marker-green-135-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-135-in1_0.png'},
    180: {min: 157.5, max: 202.5, url: 'assets/img/markers/marker-green-180.png', url_in1_1: 'assets/img/markers/marker-green-180-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-180-in1_0.png'},
    225: {min: 202.5, max: 247.5, url: 'assets/img/markers/marker-green-225.png', url_in1_1: 'assets/img/markers/marker-green-225-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-225-in1_0.png'},
    270: {min: 247.5, max: 292.5, url: 'assets/img/markers/marker-green-270.png', url_in1_1: 'assets/img/markers/marker-green-270-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-270-in1_0.png'},
    315: {min: 292.5, max: 337.5, url: 'assets/img/markers/marker-green-315.png', url_in1_1: 'assets/img/markers/marker-green-315-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-315-in1_0.png'}
  };

  static iconsRed: any = {
    0: {min: 337.5, max: 22.5, url: 'assets/img/markers/marker-red-0.png', url_in1_1: 'assets/img/markers/marker-red-0-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-0-in1_0.png'},
    45: {min: 22.5, max: 67.5, url: 'assets/img/markers/marker-red-45.png', url_in1_1: 'assets/img/markers/marker-red-45-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-45-in1_0.png'},
    90: {min: 67.5, max: 112.5, url: 'assets/img/markers/marker-red-90.png', url_in1_1: 'assets/img/markers/marker-red-90-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-90-in1_0.png'},
    135: {min: 112.5, max: 157.5, url: 'assets/img/markers/marker-red-135.png', url_in1_1: 'assets/img/markers/marker-red-135-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-135-in1_0.png'},
    180: {min: 157.5, max: 202.5, url: 'assets/img/markers/marker-red-180.png', url_in1_1: 'assets/img/markers/marker-red-180-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-180-in1_0.png'},
    225: {min: 202.5, max: 247.5, url: 'assets/img/markers/marker-red-225.png', url_in1_1: 'assets/img/markers/marker-red-225-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-225-in1_0.png'},
    270: {min: 247.5, max: 292.5, url: 'assets/img/markers/marker-red-270.png', url_in1_1: 'assets/img/markers/marker-red-270-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-270-in1_0.png'},
    315: {min: 292.5, max: 337.5, url: 'assets/img/markers/marker-red-315.png', url_in1_1: 'assets/img/markers/marker-red-315-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-315-in1_0.png'}
  };

  originalUrl: any;
  currentPath: string;
  menuVisible: boolean = true;

  static organizationConfig = new Subject<any>();
  static organizationConfig$ = AppMobileComponent.organizationConfig.asObservable();

  hideBilling: boolean;
  isDev: boolean;
  isForcedAuth: boolean;

  widthAlert: any;
  private flag_view_check: boolean;

  device_id: DeviceId;
  device_info: DeviceInfo;
  app_info: AppInfo;

  version: string = environment.version;

  constructor(
    private platform: Platform,
    private api: ApiService,
    private translate: TranslateService,
    private view: ViewService,
    public data: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private googleMap: GoogleMapsService,
    private auth: AuthService,
    private location: Location,
    private modalController: ModalController,
    private storage: Storage,
    private toastController: ToastController,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    SplashScreen.hide();
    SplashScreen.show({autoHide: false});
    this.platform.ready().then(async () => {
      try{
        firebase.initializeApp(environment.firebaseConfig);
        this.originalUrl = environment.originalUrl;

        /** Lettura parametri per attivazione tracker **/
        DataService.activationImei = this.originalUrl.searchParams.get("activationImei");
        DataService.activationSerial = this.originalUrl.searchParams.get("activationSerial");
        DataService.activationModel = this.originalUrl.searchParams.get("activationModel");
        DataService.registrationEmail =  this.originalUrl.searchParams.get("registrationEmail");

        /** Parametri per autenticazione ticket */
        const c_phone: string = this.originalUrl.searchParams.get("customerPhone");
        const o_phone: string = this.originalUrl.searchParams.get("ownerPhone");
        if(c_phone) DataService.customerPhone = `+${c_phone}`;
        if(o_phone) DataService.ownerPhone = `+${o_phone}`;

        /** Identificazione app **/
        ApiService.platformType = environment.platform_type || 1;

        /** Settaggio lingua app come lingua browser **/
        await this.setLang();

        /** Verifica tipologia piattaforma **/
        await this.checkNativeApp();

        /** Verifica se è necessario aprire la versione desktop **/
        this.checkWidthVersion();

        /** Inizializzazione statistiche **/
        StatisticsService.initialization(firebase, 'balin_mobile');
        if(DataService.activationImei && DataService.activationSerial){
          StatisticsService.sendLogEventGoogle('tracker_activation_start_wa', {imei: DataService.activationImei}); // Invia evento se in attivazione
        }

        /** Settaggio api url e modalità api **/
        if(DataService.nativeApp){
          this.api.setModality(environment.baseUrl_nativeApp, environment.communicationBaseUrl_nativeApp); // se app nativa usa url dominio balin
        }else{
          this.api.setModality(environment.baseUrl, environment.communicationBaseUrl); // se web app usa link diretto cloud run
        }


        /** Ottinimento dati general_parameter (firebase realtime database) **/
        const data: any = await this.api.getConfigInfo();
        DataService.generalConfig.setData(data, ApiService.platformType);
        if(!DataService.generalConfig) throw new Error('No general parameters found');
        DataService.hasGeneralConfig = true;
        DataService.onGeneralConfig.next(true)

        /** Controllo versione app nativa **/
        await this.checkAppVersion();

        /** Inizializzazione servizio di navigazione **/
        NavigationService.initialize(this.route, this.router, this.location); // Init del service di navigazione

        /** Inizializzazione service view con icone ed elementi specifici della piattaforma **/
        this.view.initialize({
          info: 'assets/img/icons-fdc/infoCircle_main-softer.svg',
          succ: 'assets/img/icons-fdc/check-circle_succ.svg',
          warn: 'assets/img/icons-fdc/alert-triangle_warn.svg'
        }, true);

        /** Settaggio parametri/azioni autenticazione **/
        AuthService.forcedEmail = this.originalUrl.searchParams.get("forcedEmail");
        AuthService.forcedToken = this.originalUrl.searchParams.get("forcedToken");
        AuthService.redirect =  this.originalUrl.searchParams.get("redirect");
        AuthService.vehicle_id = this.originalUrl.searchParams.get('vehicle_id'); // da app per gli allarmi arrivva così
        AuthService.openModal =  this.originalUrl.searchParams.get("openModal");

        if(AuthService.forcedEmail && AuthService.forcedToken) this.isForcedAuth = true;


        /** Settaggio parametri per gestione autenticazione con token **/
        AuthService.authTokenInUrl = this.originalUrl.searchParams.get("authToken") || await this.storage.get('authToken')
        AuthService.authStateChange.subscribe(async (loggedIn: boolean) => {
          this.manageAuth(loggedIn);
        });

        /** Avvio operazioni di login tramite inizializzazione componente **/
        this.auth.initialization(firebase, this.api);

        /** Verifico se sono in dev o prod **/
        this.checkProdDevMod();

        /** Gestione flotta **/
        AuthService.fleet = this.originalUrl.searchParams.get('fleet');

        /** inizializzazione google maps **/
        this.googleMap.initialization(google, OverlappingMarkerSpiderfier);

      }catch(err){
        this.manageStartUpError(err);
      }
    });
  }

  // Gestisce le azioni da eseguire dopo che firebase autenticazione si è inizializzato
  private async manageAuth(loggedIn: boolean){
    if(Capacitor.isNativePlatform()){
      try{
        await PushNotifications.removeAllDeliveredNotifications();
      }catch(err){
        console.error('Impossile azzerare il badge di notifiche', err);
      }
    }

    try{
      if(loggedIn){
        await this.data.initialize(this.api, firebase);
        this.manageTokenFCM();
        if (
          this.data.organizations.length === 1 &&
          !this.data.selOrganization.translate_language
        ) {
          await this.storage.get("organization_language").then(async (lang: any) => {
            if(lang){
              lang = {code: lang};
            }else{
              lang = {code: 'it'};
            }
            let timezone: string = moment.tz.guess(true) || "Europe/Rome";
            let tz: any = DataService.generalConfig.timezones.find((elem: any) => {
              return elem === timezone;
            });
            if(!tz) timezone = "Europe/Rome";
            await this.api.patchOrganization(this.data.selOrganization._id, this.data.selOrganization.me._id, lang.code, timezone, undefined, true).then((res: any) => {
              this.data.selOrganization.setData(res);
              console.log('patch organization ok');
            }).catch((err: any) => {
              console.error('path organization', err);
            })
          });
        }
        // * set app language
        await this.setLang({
          langCode: this.data.selOrganization.translate_language,
        });
        console.log(AuthService.redirect)
        if(AuthService.redirect){
          if(AuthService.vehicle_id){
            NavigationService.navigate(AuthService.redirect, true, false, {vehicle_id: AuthService.vehicle_id});
          }else{
            NavigationService.navigate(AuthService.redirect);
          }
        }else{
          if(this.originalUrl.pathname !== '/change-password' && this.originalUrl.pathname !== '/free-trial' && this.originalUrl.pathname !== '/shop' && this.originalUrl.pathname !== '/tabs/menu/shop'){
            NavigationService.navigate('tabs', true);
          }else{
            NavigationService.navigate(this.originalUrl.pathname);
          }
        }
        this.checkPaymentsProblems();

        this.checkAdvContact();

        if(!this.auth.isForced){
          try{
            if(this.data.selOrganization?.billing_type === 2){
              StatisticsService.sendLogEventGoogle('clienti_azienda'); // Invia evento se in attivazione
            }
          }catch(err){
            console.error(err);
          }
        }

        if(DataService.customerPhone && DataService.ownerPhone){
          /** Il cliente sta accedendo all'app dal link di login letto da whatsapp, chiamata api per associare il contatto  */
          try{
            console.log('Sono in associazione organization - ticket')
            await this.api.associateOrganizationCommunication(DataService.customerPhone, DataService.ownerPhone)
            //TODO: gestire il success mostrando un alert con "associazione avvenuta correttamente"
            NavigationService.navigate('tabs/menu/support', true);
          }catch(err){
            console.error('Errore durante l\'associazione del ticket con org');
          }
        }

        SplashScreen.hide();
      }else{
        this.removeTokenFCM();
        this.api.storage.clear();
        MainMapService.clearService();
        this.data.isDemo = this.data.resetDemo();
        if(this.originalUrl.pathname !== '/free-trial'/* && this.originalUrl.pathname !== '/shop' && this.originalUrl.pathname !== '/tabs/menu/shop'*/){ // in shop nopn si va più se non si è loggati
          NavigationService.navigate('login', true);
        }else{
          NavigationService.navigate(this.originalUrl.pathname, true);
        }
        SplashScreen.hide();
      }
    }catch(err: any){
      console.error(err);
      if(err?.error?.type === 1){
        this.view.presentAlert("Attenzione", "Questo utente non è di questa piattaforma", [{
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            location.reload();
          }
        }]);
        await this.auth.logout();
        return;
      }
      if(AuthService.authTokenInUrl){
        console.error('Errore di login con un token scaduto o invalido', err);
        await this.auth.logout();
        return;
      }
    }

    /** Setting dei colori della statusbar **/
    this.setStatusBar();

    App.addListener('backButton', event => {
      if(!event.canGoBack){
        App.exitApp();
      }else{
        window.history.back();
      }
    });
  }

  // Controlla che la versione dell'app nativa sia valida e non necessiti di aggiornare l'app o non abbia bisogno di ascuramente per la review
  private async checkAppVersion(){
    if(DataService.nativeApp === 'ios'){
      const version: number = Utility.parseVersionNumber(this.app_info.version);
      this.version = this.app_info.version;
      if(DataService.generalConfig?.ios_review_version && version >= Utility.parseVersionNumber(DataService.generalConfig.ios_review_version)){
        // Dico all'app di oscurare alcune parti
        this.hideBilling = true;
      }
      if(DataService.generalConfig?.ios_min_version && version < Utility.parseVersionNumber(DataService.generalConfig.ios_min_version)){
        console.log('version --> need_app_update');
        throw new Error('need_app_update');
      }
    } else if(DataService.nativeApp === 'android'){
      const version: number = Utility.parseVersionNumber(this.app_info.version);
      this.version = this.app_info.version;
      if(DataService.generalConfig?.android_min_version && version < Utility.parseVersionNumber(DataService.generalConfig.android_min_version)){
        throw new Error('need_app_update');
      }
    }
    DataService.version = this.version;
  }

  // Gestisce i vari errori possibile nel processo di avvio dell'app
  private manageStartUpError(err){
    console.error(err);
    SplashScreen.hide();
    this.translate.get('START_INFO').subscribe((lang: any) => {
      if(lang){
        if(err?.message === 'need_app_update'){
          this.view.presentAlert(lang.versione_app, lang.versione_app_info, [{
            text: lang.aggiorna,
            handler: () => {
              let url: string = "https://play.google.com/";
              if(DataService.generalConfig && DataService.generalConfig.sito && DataService.generalConfig.sito.io_store && DataService.generalConfig.sito.io_store.play_store){
                url = DataService.generalConfig.sito.io_store.play_store;
              }
              if(DataService.nativeApp === 'ios'){
                url = "https://www.apple.com/it/ios/app-store/";
                if(DataService.generalConfig && DataService.generalConfig.sito && DataService.generalConfig.sito.io_store && DataService.generalConfig.sito.io_store.app_store){
                  url = DataService.generalConfig.sito.io_store.app_store;
                }
              }
              // this.iab.create(url, '_system');
              AppLauncher.openUrl({ url: url });
              return false;
            }
          }], undefined, undefined, false);
        }else{
          this.view.presentAlert(lang.ops, lang.servizio_non_disponibile, [], undefined, undefined, false);
        }
      }else{
        this.view.presentAlert('Error', 'Service temporary not available', [], undefined, undefined, false);
      }
    });
  }

  // Verifica se il progetto di firebase è quello di prod o di dev
  checkProdDevMod(){
    try{
      if(firebase.app().options.projectId === 'white-label-dev-635e0'){
        this.isDev = true;
      }else{
        this.isDev = false;
      }
    }catch(err){
      console.error(err);
    }
  }

  setPath(path: string){
    this.currentPath = path;
    switch (this.currentPath) {
      case 'login':
        this.menuVisible = false;
        break;
      default:
        this.menuVisible = true;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  async checkPermission(functionalityType: any, closeModal?: any): Promise<boolean>{
    try{
      if(!this.data.selOrganization.me.functionalityIsEnabled(functionalityType)){
        let navigationExtras: any = {
          functionality: functionalityType
        };
        console.log(navigationExtras);
        if(!closeModal){
          await NavigationService.navigate('tabs/not-permitted', true, false, navigationExtras);
          // return await this.navController.navigateRoot('not-permitted', navigationExtras);
        }else{
          // await closeModal();
          // await this.navController.navigateRoot('not-permitted', navigationExtras);
        }
        return false;
      }
    }catch(err){
      console.log('No yet data init');
    }
    return true;
  }

  resetCurrentMap(){
    this.googleMap.currentMap = undefined;
  }

  // Visualizza se necessario l'alert per l'installazione dell'app nativa se siamo su web
  showInstallationAlert(){
    setTimeout(() => {
      if(
        this.platform.is('mobileweb')
      ){
        let icon = 'assets/img/icons-fdc/logo-play-store-colored.svg';
        let url: string = "https://play.google.com/";
        if(DataService.generalConfig && DataService.generalConfig.sito && DataService.generalConfig.sito.io_store && DataService.generalConfig.sito.io_store.play_store){
          url = DataService.generalConfig.sito.io_store.play_store;
        }
        if(this.platform.is('ios')){
          icon = "assets/img/icons-fdc/logo-app-store-colored.svg";
          url = "https://www.apple.com/it/ios/app-store/";
          if(DataService.generalConfig && DataService.generalConfig.sito && DataService.generalConfig.sito.io_store && DataService.generalConfig.sito.io_store.app_store){
            url = DataService.generalConfig.sito.io_store.app_store;
          }
        }
        this.translate.get('APP').subscribe(async (lang: any) => {
          if(lang){
            const toast = await this.toastController.create({
              message: lang.installa_app_info,
              color: 'light',
              buttons: [
                {
                  side: 'start',
                  icon: "close",
                  role: 'cancel',
                  handler: () => {}
                },
                {
                  text: lang.scarica,
                  icon: icon,
                  handler: () => {
                    // this.iab.create(url, '_system');
                    AppLauncher.openUrl({ url: url });
                  }
                }
              ]
            });
            toast.present();
          }
        });
      }

    }, DataService.generalConfig.time_hint_native_app);
  }

  async manageTokenFCM(){
    try{
      if(Capacitor.isNativePlatform()){

        console.log('Device UUID is: ' + this.device_id?.identifier);

        PushNotifications.createChannel({
          id: "alarms",
          description: "Channel for alarms",
          name: "Channel alarms",
          sound: "alarms",
          vibration: true,
          lights: true,
          lightColor: parseInt("ffffff00", 16).toString(), // Arancione
          importance: 4,
          visibility: 1
        }).then((channel: any) => {
          console.log('channel', channel)
        }).catch((err: any) => {
          console.error('Errore creazione channel', err);
        });

        // PushNotifications.requestPermissions().then(state => {
        //   console.log(`Permesso richiesto e accetato: ${JSON.stringify(state)}`);
        //   console.log(state);
        // }).catch(error => console.error('Error getting token', error));

        PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('Push notification received: ', notification);
        });

        PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);

          if(notification?.notification){
            // Notifica aperta dall'esterno
            try{
              console.log(JSON.stringify(notification.notification));
              let payload: any = JSON.parse(notification.notification.data.data);
              console.log(payload);
              switch (payload.type) {
                case 'alarm':
                  NavigationService.navigate('tabs/notifications', true, false, {idNotification: payload.idInternalNotification});
                  break;
              }
            }catch(err){
              console.error(err);
            }
          }
        });

        PushNotifications.addListener('registration', async token => {
          console.log('Registration token: ', token.value);
          try{
            await this.api.postTokenFCM(this.data.selOrganization.me._id, token.value, this.device_id?.identifier);

            PushNotifications.removeAllDeliveredNotifications().then(() => {
              console.log('Badge notifiche rimosso');
            }).catch((err: any) => {
              console.error('Errore rimozione badge notifiche', err);
            });

          }catch(err){
            console.error("Error saving token", err);
          }
        });

        PushNotifications.addListener('registrationError', async error => {
          console.log('Errore in registrazione token', error);
          alert('Errore registrazione token');
        });


        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          console.log('richiede i permessi')
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        await PushNotifications.register();

      }
    }catch(err){
      console.error("Errore in manageTokenFCM", err);
    }
  }

  async removeTokenFCM(){
    try{
      const device_id: DeviceId = await Device.getId();
      await this.api.deleteTokenFCM(device_id.identifier);
    }catch(err){
      console.error(err);
    }
  }

  // Verifica se è un app nativa
  private async checkNativeApp(){
    if(Capacitor.isNativePlatform()){
      this.device_id = await Device.getId();
      this.device_info = await Device.getInfo();
      this.app_info = await App.getInfo();
      if(this.device_info?.platform === 'ios'){
        DataService.nativeApp = 'ios';
        return;
      }else if(this.device_info?.platform === 'android'){
        DataService.nativeApp = 'android';
        return;
      }
    }
  }

  changeUrl(destination_url: string){
    let url: string = destination_url;
    // if(DataService.customerPhone && DataService.ownerPhone){
    //   url += `?customerPhone=${DataService.customerPhone.replace('+', '')}&ownerPhone=${DataService.ownerPhone.replace('+', '')}`;
    // }
    try{
      url = this.originalUrl.href.replace(this.originalUrl.origin, url); // Se avessi parametri in url sono gestiti
    }catch(error){
      console.error(error);
      url = destination_url;
    }
    window.location.href = url;
  }

  // Verifica se la dimensione dello schermo necessita di far vedere la versione desktop (SOLO PROD)
  private checkWidthVersion(){
    try{
      if(!DataService.nativeApp && environment.desktopUrl){
        const max_width: number = 1024;
        if(window.innerWidth >= max_width){
          this.changeUrl(environment.desktopUrl);
        }else{
          window.onresize = async () => {
            if(this.flag_view_check) return;
            this.flag_view_check = true;
            try{
              if(window.innerWidth >= max_width){
                if(!this.widthAlert){
                  const lang: any = this.translate.instant('DIMENSION_ALERT');
                  this.widthAlert = await this.alertController.create({
                    header: lang.titolo,
                    message: lang.messaggio,
                    buttons: [
                      {
                        text: lang.annulla,
                        role: 'cancel',
                        handler: () => {
                          this.widthAlert = undefined;
                        }
                      },
                      {
                        text: lang.risposta,
                        handler: () => {
                          this.changeUrl(environment.desktopUrl);
                        }
                      }
                    ]
                  });
                  await this.widthAlert.present();
                }
              }else{
                if(this.widthAlert){
                  await this.widthAlert.dismiss();
                  this.widthAlert = undefined;
                }
              }
            }catch(err){
              console.error(err);
            }
            this.flag_view_check = false;
          };
        }
      }
    }catch(err){
      console.error('Cambio url non attivo perchè in DEV mod, oppure per un altro errore.');
    }
  }

  // Effettua le verifiche sui pagamenti

  hint_modal_opened: boolean;
  async checkPaymentsProblems(){
    if(!this.data.selOrganization.login_view_demo){
      if(this.data.selOrganization.login_blocked){
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'delinquent'
          },
          backdropDismiss: false,
          cssClass: "modal_alarm"
        });
        return await modal.present();
      } else if(!this.hint_modal_opened && this.data.selOrganization.login_need_default_payment_method){
        this.hint_modal_opened = true;
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'hint'
          },
          cssClass: "modal_alarm"
        });
        return await modal.present();
      }else if(!this.hint_modal_opened && this.data.selOrganization.login_stripe_invoices_unpaid_count > 0 && !this.data.selOrganization.stripe_disable_add_payment){
        this.hint_modal_opened = true;
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'delinquent',
            dismissible: true
          },
          backdropDismiss: false,
          cssClass: "modal_alarm"
        });
        return await modal.present();
      }
    }
  }

  // Effettua l'api per associare ip o cookie ad una specifica adv a fini statistici
  async checkAdvContact(){
    if(this.isForcedAuth) return; //Non fa api di match contatti
    try{
      let cookie: string, ip: any;
      try{
        ip = await this.api.getIp();
        if(ip?.ip){
          ip = ip.ip;
        }else{
          ip = undefined;
        }
      }catch(err){
        console.error('Error reading ip', err);
      }
      try{
        const nameEQ = "cookieAdvBalin=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0){
            cookie = c.substring(nameEQ.length, c.length);
            break;
          }
        }

      }catch(err){
        console.error('Error reading cookie', err);
      }
      console.log(ip, cookie);
      if(ip || cookie){
        await this.api.postMatchContact(this.data.selOrganization.me._id, ip, cookie);
      }
    }catch(err){
      console.error('Error in match adv contact', err);
    }
  }

  // Imposta lo stile della status bar
  async setStatusBar(){
    try{
      await StatusBar.setStyle({style: Style.Dark});
      await StatusBar.setBackgroundColor({color: '#2655bf'});
      await StatusBar.setOverlaysWebView({overlay: false});
    }catch(err){
      console.warn('Impossibile impostare la status bar', err);
    }
  }

  // * language
  setLang(args: { langCode?: string } = {}) {
    return new Promise((resolve, reject) => {
      let { langCode } = args
      if (!langCode) {
        switch (this.translate.getBrowserLang() || navigator.language) {
          case "it":
          case "it-it":
          case "it-IT":
            langCode = "it";
            break;

          default:
            langCode = "en"
        }
        this.translate.use(langCode).subscribe(() => {
          console.log('LINGUA CARICATA');
          return resolve(null);
        })
      } else {
        const found = DataService.generalConfig.languages.find(
          obj => obj.code === langCode
        )
        this.translate.use(found?.code ?? "en").subscribe(() => {
          console.log('LINGUA CARICATA da organization');
          return resolve(null);
        })
      }
    });

  }
}
