import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  NavController,
} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {Ticket} from "../../../../classes/common/zendesk/ticket";
import {ViewService} from "../../../../services/common/view.service";
import {DataService} from "../../../../services/common/data.service";
import {ApiService} from "../../../../services/common/api.service";
import {AuthService} from "../../../../services/common/auth.service";
import {Utility} from "../../../../classes/common/utility";

type Inputs = 'name' | 'email' | 'tel' | 'subject' | 'message';
const MEDIA_MAX_SIZE: number = 1048576;


@Component({
  selector: 'app-ticket-add',
  templateUrl: './ticket-add.page.html',
  styleUrls: ['./ticket-add.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketAddPage implements OnInit {

  @Input() isModal: boolean;

  lang: any;
  name: string;
  nameError: string;
  email: string;
  emailError: string;
  tel: string;
  subject: string;
  subjectError: string;
  message: string;
  messageError: string;
  tickets: Ticket[] = [];
  attach: any;


  inputs = [
    {
      lbl: "Nome richiedente",
    },

    {
      lbl: "Email di contatto",
    },

    {
      lbl: "Telefono",
    },

    // {
    //   css: "grid-col-2",
    //   lbl: "Oggetto della richiesta",
    // },

  ]

  attachments = [
    {
      name: "fattura.pdf",
    },

    {
      name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    },


    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },
    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },
    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },
    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },
    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },
    // {
    //   name: "fattura.pdf",
    // },

    // {
    //   name: "fattura2fattufattura2fattura2fattura2fattura2fattura2fattura2ra2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2fattura2.pdf",
    // },



  ]



  // nav

  pageContent = 1;


  // componentProps
  // @Input() componentProp: string;



  // listener

  listener = {
    view: null,
  }


  constructor(
    private alertCtrl: AlertController,
    private cd: ChangeDetectorRef,
    // private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    // private navParams: NavParams,
    private popoverCtrl: PopoverController,
    private route: ActivatedRoute,
    private router: Router,
    private view: ViewService,
    private location: Location,

    private data: DataService,
    private translate: TranslateService,
    private navCtrl: NavController,
    private api: ApiService,
  ) {}

  ngOnInit() {}


  ionViewWillEnter(){
    try{
      if(!AuthService.isLogged) this.navCtrl.navigateRoot('login');
    }catch(error){
      DataService.manageStartupPageError('TicketAddPage', '', error);
    }
  }


  ionViewDidEnter() {
    try{
      // console.log("ionViewDidEnter()");
      this.listenerView();

      this.translate.get('TICKETS').subscribe((lang: any) => {
        this.lang = lang;
        this.email = this.data.selOrganization.me.email;
        this.cd.detectChanges();
        // this.getData();
      });
    }catch(error){
      DataService.manageStartupPageError('TicketAddPage', '', error);
    }
  }

  ionViewWillLeave() {
    try{
      // console.log("ionViewWillLeave()");

      if (this.listener.view) {
        this.listener.view.unsubscribe();
      }
    }catch(error){
      DataService.manageStartupPageError('TicketAddPage', '', error);
    }
  }




  // tests

  logs() {
    // console.log(this.cars);
  }





  goBack() {
    // this.router.navigateByUrl("..");

    // import { Location } from '@angular/common';
    // private location: Location,
    // this.location.back();
    if(this.isModal){
      this.modalCtrl.dismiss();
    }else{
      this.navCtrl.pop()
    }
  }

  changePageContent(val: number) {
    this.pageContent += val;

    ViewService.updateView.next(undefined);
    this.refreshView()

    switch (this.pageContent) {
      case 0:
        this.goBack()
        break;

      case 3:
        this.createTicket()
        break;
    }

    this.refreshView()
  }



  // Effettua la verifica sul form
  async checkFormError(input: Inputs, noTimeout?: boolean){
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'name':
        if(!this.name){
          this.nameError = this.lang.errori.inserisci_nome;
          break;
        }
        this.nameError = undefined;
        break;
      case 'email':
        if(!this.email){
          this.emailError = this.lang.errori.inserisci_email;
          break;
        }
        if(!Utility.validateEmail(this.email)){
          this.emailError = this.lang.errori.email_invalida;
          break;
        }
        this.emailError = undefined;
        break;
      case 'subject':
        if(!this.subject){
          this.subjectError = this.lang.errori.inserisci_oggetto;
          break;
        }
        this.subjectError = undefined;
        break;
      case 'message':
        if(!this.message){
          this.messageError = this.lang.errori.inserisci_messaggio;
          break;
        }
        this.messageError = undefined;
        break;
    }
    ViewService.updateView.next(undefined);
    this.refreshView()
  }

  // Apre il selettore del file e avvia il caricamento sul client
  selectFile() {
    let input: any = document.createElement('input');
    input.type = 'file';
    input.accept = "image/*";
    input.addEventListener("change", (event) => {
      this.uploadFile(event);
      this.refreshView()
      this.cd.detectChanges()
      this.cd.markForCheck()
    });
    input.click();
    this.refreshView()
    this.cd.detectChanges()
    this.cd.markForCheck()
  }

  // Affettua il read del file e controlla la dimensione
  private uploadFile(event: any) {
    let target = event.target || event.srcElement;
    if (target.files[0]) {
      if(target.files[0].size > MEDIA_MAX_SIZE){
        this.view.modalAlert(this.lang.errori.errore, this.lang.errori.allegato_troppo_grande + ' ' + (MEDIA_MAX_SIZE / 1000000) + ' MB', 'warn', this.lang.ok);
      }
      this.attach = {
        name: target.files[0].name,
        type: target.files[0].type.split("/")[0]
      };
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(new Blob([target.files[0]], { type: target.files[0].type }));
      reader.onload = () => {
        this.attach.body = reader.result;

        ViewService.updateView.next(undefined);
        this.refreshView()
        this.cd.detectChanges()
        this.cd.markForCheck()
      };
    }

    this.refreshView()
  }

  // Apre un nuovo ticket
  async createTicket(){
    this.checkFormError('name', true);
    this.checkFormError('email', true);
    this.checkFormError('subject', true);
    this.checkFormError('message', true);
    if(this.nameError || this.emailError || this.subjectError || this.messageError) {
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.campi_obbligatori, 'warn', this.lang.ok);
      this.changePageContent(-1)
      this.refreshView()
      return;
    };
    try{
      await this.view.showLoading();
      if(this.attach){
        await this.api.postTicket(this.data.selOrganization.me._id, this.name, this.email, this.subject, this.message, this.tel, this.attach.name, this.attach.body);
      }else{
        await this.api.postTicket(this.data.selOrganization.me._id, this.name, this.email, this.subject, this.message, this.tel);
      }
      await this.view.hideLoading();
      this.attach = undefined;
      this.subject = undefined;
      this.message = undefined;
      // this.getData();
      ViewService.updateView.next(undefined);
      this.refreshView()
      // this.view.modalAlert(this.lang.successo, this.lang.ticket_creato, 'succ', this.lang.ok);
      this.view.modalAlert(this.lang.confirm_alert_title, this.lang.confirm_alert_msg, 'succ', this.lang.ok);

      this.goBack()
    }catch(err){
      console.error(err);
      this.view.hideLoading();
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.creazione_ticket, 'warn', this.lang.ok);

      this.refreshView()
      this.changePageContent(-1)
      this.refreshView()
    }
  }



  // listener

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      // console.log("listener.view", obj);

      this.view.pipeChanged++;
      this.cd.markForCheck();

      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);

    });
  }

  refreshView() {
    ViewService.updateView.next(undefined);
  }








}

