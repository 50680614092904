// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-item {
  display: flex;
  color: var(--app-mid);
  font-size: 17px;
  font-weight: 500;
}
.flex-item.column {
  flex-direction: column;
}
.flex-item.row {
  flex-direction: row;
}
.flex-item.bg-gray {
  background-color: #F5F6F8;
}
.flex-item .card-icon {
  height: 2rem;
  width: 2rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.flex-item .text-content {
  font-weight: 400;
}

.card {
  padding: 2.5rem;
}
.card .card-content {
  font-weight: 300;
  min-height: 135px;
  flex-grow: 1;
}
.card .card-content p {
  line-height: 1.2;
  text-align: center;
  padding: 0 2rem 0 2rem;
}
.card .card-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.card .card-footer .img {
  grid-column-start: 2;
}

.center {
  justify-items: center;
  align-items: center;
  text-align: center;
}

.bold {
  font-weight: bold;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .flex-item.row {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/step-card/step-card.component.scss"],"names":[],"mappings":"AAKA;EACE,aAAA;EAWA,qBAAA;EACA,eAlBgB;EAmBhB,gBAAA;AAdF;AAEE;EACE,sBAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AAOE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,mBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;;AAUA;EACE,eAAA;AAPF;AAQE;EACE,gBAAA;EACA,iBAAA;EACA,YAAA;AANJ;AAOI;EACE,gBAAA;EACA,kBAAA;EACA,sBAAA;AALN;AASE;EACE,aAAA;EACA,kCAAA;AAPJ;AAQI;EACE,oBAAA;AANN;;AAWA;EACE,qBAAA;EACA,mBAAA;EACA,kBAAA;AARF;;AAWA;EACE,iBAAA;AARF;;AAYA;EAEI;IACE,sBAAA;EAVJ;AACF","sourcesContent":["$fontSizeDefault: 17px;\n$fontSizeSmall: 15px;\n$fontBig: 22px;\n\n\n.flex-item{\n  display: flex;\n  &.column{\n    flex-direction: column;\n  }\n  &.row{\n    flex-direction: row;\n  }\n  &.bg-gray{\n    background-color: #F5F6F8;\n  }\n\n  color: var(--app-mid);\n  font-size: $fontSizeDefault;\n  font-weight: 500;\n\n  .card-icon{\n    height: 2rem;\n    width: 2rem;\n    margin-top: 2rem;\n    margin-bottom: 1rem;\n  }\n\n  .text-content{\n    font-weight: 400;\n  }\n}\n\n.card{\n  padding: 2.5rem;\n  .card-content{\n    font-weight: 300;\n    min-height: 135px;\n    flex-grow: 1;\n    p{\n      line-height: 1.2;\n      text-align: center;\n      padding: 0 2rem 0 2rem;\n\n    }\n  }\n  .card-footer{\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    .img{\n      grid-column-start: 2;\n    }\n  }\n}\n\n.center{\n  justify-items: center;\n  align-items: center;\n  text-align: center;\n}\n\n.bold{\n  font-weight: bold;\n}\n\n\n@media only screen and (min-width : 320px) and (max-width: 768px) {\n  .flex-item{\n    &.row{\n      flex-direction: column;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
