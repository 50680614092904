import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input,
  OnInit
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {
  ModalController,
  NavController, PopoverController
} from "@ionic/angular";
import * as moment from "moment";
import {AppComponent} from "../../../../app.component";
import {
  Partecipant
} from "../../../../classes/common/partecipant";
import {
  BillingLocation,
  BillingType, Organization
} from "../../../../classes/common/organization";
import {
  ViewService
} from "../../../../services/common/view.service";
import {
  DataService
} from "../../../../services/common/data.service";
import {
  ApiService
} from "../../../../services/common/api.service";
import {
  AuthService
} from "../../../../services/common/auth.service";
import {Utility} from "../../../../classes/common/utility";
import {
  PopoverFlagComponent
} from "../../../../components/desktop/popover-flag/popover-flag.component";
import {
  StatisticsService
} from "../../../../services/common/statistics.service";
import { StripeService } from "../../../../services/common/stripe.service";
import {ImageDetailComponent} from "src/app/components/common/image-detail/image-detail.component";


declare var Stripe: any;

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.page.html',
  styleUrls: ['./user-data.page.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDataPage implements OnInit {

  @Input() fromActivation: boolean;
  @Input() appComponent: AppComponent;
  @Input() tracker: Partecipant;

  pageContent: number = 1;

  lang: any;

  steps = [
    "USER_DATA.step1",
    "USER_DATA.step2",
    "USER_DATA.step3",
    "USER_DATA.step4"
  ];

  stepsActivation = [
    "USER_DATA.step2_activation",

    "USER_DATA.step3_activation",
    "USER_DATA.step4_activation"
  ];

  billingType: BillingType;
  billingTypeError: string;
  billingLocation: BillingLocation;
  billingLocationError: string;
  paymentMethod: string = 'card';

  name: string;
  nameError: string;
  surname: string;
  surnameError: string;
  address: string;
  addressError: string;
  zip: string;
  zipError: string;
  // useful when we want the msg for the alert != msg for the input
  zipErrorAlert: string;
  city: string;
  cityError: string;
  province: string;
  provinceCode: string;
  provinceError: string;
  // email: string;
  emailError: string;
  phone: string;
  companyName: string;
  companyNameError: string;
  vat: string;
  vatError: string;
  // useful when we want the msg for the alert != msg for the input
  vatErrorAlert: string;
  fiscalCode: string;
  fiscalCodeError: string;
  // useful when we want the msg for the alert != msg for the input
  fiscalCodeErrorAlert: string;
  nation: string;
  nationCode: string;
  nationError: string;
  pec: string;
  pecError: string;
  sdi: string;
  sdiError: string;

  backLabel: string = 'annulla';
  stripe: any;
  stripeCardElement: any;
  stripeError: any;
  stripeCardAdded: boolean;

  model: number;
  modelError: string;

  imei: string;
  imeiError: string;
  serial: string;
  serialError: string;

  vehicleName: string;
  vehicleNameError: string;
  label: number;
  labelError: string;
  odometer: number;
  odometerError: string;

  activationError: string;
  activationErrorDescription: string;

  freeTrialExpiration: number = moment().add(30, 'days').valueOf();

  monthly_cost: number = 4.9;

  is_info_no_position: boolean;

  // listener
  listener = {
    view: null,
    updateData: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private view: ViewService,
    private data: DataService,
    private api: ApiService,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
    private stripeService: StripeService
  ) {
    if(this.data.selOrganization){
      this.billingType = this.data.selOrganization.billing_type;
      this.billingLocation = this.data.selOrganization.billing_location;
      this.companyName = this.data.selOrganization.billing_companyName;
      this.name = this.data.selOrganization.billing_name;
      this.surname = this.data.selOrganization.billing_surname;
      this.address = this.data.selOrganization.billing_address;
      this.zip = this.data.selOrganization.billing_zip;
      this.city = this.data.selOrganization.billing_city;
      this.province = this.data.selOrganization.billing_province;
      if(this.billingLocation === 1 && this.province){
        let p: any = DataService.generalConfig.getProvinceIta(this.province);
        if(p){
          this.province = p.nome;
          this.provinceCode = p.sigla;
        }
      }
      this.nationCode = this.data.selOrganization.billing_country;
      if(this.nationCode){
        let n: any = DataService.generalConfig.getNation(this.nationCode);
        if(n) this.nation = n.name;
      }
      // this.email = this.data.selOrganization.billing_mail;
      this.phone = this.data.selOrganization.billing_phone;
      this.vat = this.data.selOrganization.billing_vat;
      this.pec = this.data.selOrganization.billing_mailPEC;
      this.sdi = this.data.selOrganization.billing_sdiCode_fatturaElettronica;
      this.fiscalCode = this.data.selOrganization.billing_codiceFiscale;
    }
  }

  async ngOnInit() {
    if(this.fromActivation){
      this.imei = DataService.activationImei;
      this.serial = DataService.activationSerial;
      switch (DataService.activationModel) {
        case 'FMB920':
          this.model = 1;
          break;
        case 'FMB020':
          this.model = 2;
          break;
        case 'FMC920':
          this.model = 3;
          break;
      }
      if(!this.data.selOrganization.billing_setting){
        this.pageContent = 0; // pagina inserimento anagrafica
      }else{
        if(this.data.selOrganization.login_need_default_payment_method){
          this.pageContent = 7; // pagina scelta metodo pagamento
        }else{
          this.goToActivation();
        }
      }
    }
    if(this.tracker){
      this.pageContent = 16; // pagina di spiegazione tracker senza posizioni
      this.is_info_no_position = true;
    }
    this.translate.get('USER_DATA').subscribe((lang: any) => {
      this.lang = lang;
    });
    this.stripe = Stripe(DataService.generalConfig.stripe_public_key);
    ViewService.updateView.next(undefined);
  }

  async ionViewWillEnter(){
    if(!AuthService.isLogged) this.navCtrl.navigateRoot('login');
    if(this.fromActivation){
      if(this.appComponent) return await this.appComponent.checkPermission('activation', async () => {await this.dismiss()});
    }else{
      if(this.appComponent) return await this.appComponent.checkPermission('billing', async () => {await this.dismiss()});
    }
  }

  ionViewDidEnter() {
    // this.view.modalAlert(this.lang.attivazione_successo, this.lang.dispositivo_attivato, 'succ', this.lang.ok);

    this.listenerView();
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
    DataService.clearActivationParams();
  }

  // Va nella pagina corretta dell'iter attivazione, e sceglie se partire dalla prima pagina o da quella di dati del veicolo
  private goToActivation(){
    console.log(`goToActivation()`, )
    if(this.imei && this.serial){
      console.log(`goToActivation() if`, )
      this.backLabel = "indietro";
      this.pageContent = 12; // 10 // terza pagina attivazione localizzatori
    }else{
      console.log(`goToActivation() else`, )
      this.backLabel = 'annulla';
      this.pageContent = 10; // 10 // prima pagina attivazione localizzatore
    }
  }

  async changePageContent(isForward: boolean) {
    switch (this.pageContent) {
      case 0: // pagina info richiesta dati anagrafici
        if(isForward){
          this.backLabel = 'indietro';
          this.pageContent = 1;
        }else{
          return this.closeConfirmation();
        }
        break;
      case 1: // pagina tipologia fatturazione
        if(isForward){
          this.checkFormError('billingType', true);
          if(this.billingTypeError){
            return this.view.modalAlert(this.lang.attenzione, this.billingTypeError, 'warn', this.lang.ok);
          }
          this.backLabel = 'indietro';
          this.pageContent = 2;
        }else{
          if(this.fromActivation){
            this.backLabel = 'annulla';
            this.pageContent = 0;
          }else{
            return this.closeConfirmation();
          }
        }
        break;
      case 2: // pagina locazione per fatturazione
        if(isForward){
          this.checkFormError('billingLocation', true);
          if(this.billingLocationError){
            return this.view.modalAlert(this.lang.attenzione, this.billingLocationError, 'warn', this.lang.ok);
          }

          // some of these checks are not needed in some cases
          // to be sure: all are reset
          this.fiscalCodeError = undefined
          this.vatError = undefined
          this.zipError = undefined

          if(this.billingLocation === 1){
            this.nation = 'Italy';
            this.nationCode = 'IT';
            if(!this.provinceCode) this.province = undefined;
          }else{
            // a part of this code was taken from the constructor and
            // commented there
            let foundNationNotEligible: boolean;
            if (this.nationCode) {
              let foundNation: any = DataService.generalConfig.getNation(this.nationCode);
              if (foundNation) {
                switch (this.billingLocation) {
                  // europa
                  case 2:
                    // we don't want ITaly to be among eu nations
                    if (foundNation.eu && foundNation.code !== "IT") {
                      this.nation = foundNation.name;
                      this.nationCode = foundNation.code;
                    } else {
                      foundNationNotEligible = true;
                    }
                    break;
                  // extra-europa
                  case 3:
                    if (foundNation.eu) {
                      foundNationNotEligible = true;
                    } else {
                      this.nation = foundNation.name;
                      this.nationCode = foundNation.code;
                    }
                    break;
                }
              }
            }
            if (!this.nationCode || foundNationNotEligible) {
              // no loose ends
              this.nation = undefined;
              this.nationCode = undefined;
            }

            // this part was left as is
            if(this.provinceCode){
              this.province = undefined;
              this.provinceCode = undefined;
            }
          }
          // if(!this.email) this.email = this.data.selOrganization.me.email;
          this.backLabel = 'indietro';
          this.pageContent = 3;
        }else{
          this.backLabel = 'annulla';
          if(this.fromActivation){
            this.backLabel = 'indietro';
          }
          this.pageContent = 1;
        }
        break;
      case 3: // pagina dati fatturazione
        if(isForward){
          if(this.billingType === 1){
            this.checkFormError('name', true);
            this.checkFormError('surname', true);
            // this.checkFormError('email', true);
            this.checkFormError('address', true);
            this.checkFormError('zip', true, true);
            this.checkFormError('city', true);
            this.checkFormError('province', true);
            this.checkFormError('nation', true);
            let error: string = this.nameError || this.surnameError || this.emailError || this.addressError || this.zipError || this.cityError || this.provinceError || this.nationError;
            if(this.billingLocation === 1){
              await this.view.hideLoading()
              await this.view.showLoading(this.lang.verifica_correttezza_form)

              await this.checkFormError('fiscalCode', true);
              await this.checkFormError('zip', true);

              await this.view.hideLoading()
              error = error || this.fiscalCodeError || this.zipError;

              // only the last of these will be shown
              if (this.fiscalCodeErrorAlert) {
                error = this.lang.codice_fiscale_format_error_completo;
              }
              if (this.zipErrorAlert) {
                error = this.lang.zip_format_error_completo;
              }
            }
            if(error){
              return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
            }
          }else if(this.billingType === 2){
            this.checkFormError('companyName', true);
            this.checkFormError('vat', true, true);
            // this.checkFormError('email', true);
            this.checkFormError('address', true);
            this.checkFormError('zip', true, true);
            this.checkFormError('city', true);
            this.checkFormError('province', true);
            this.checkFormError('nation', true);
            let error: string = this.companyNameError || this.vatError || this.emailError || this.addressError || this.zipError || this.cityError || this.provinceError || this.nationError;
            if(this.billingLocation === 1){
              this.checkFormError('pec', true);
              this.checkFormError('sdi', true);
              error = error || this.pecError || this.sdiError;
            }
            // if (this.billingLocation === ) {
              await this.view.hideLoading()
              await this.view.showLoading(this.lang.verifica_correttezza_form)

              await this.checkFormError('vat', true);
              if (this.billingLocation === 1) {
                await this.checkFormError('zip', true);
              }

              await this.view.hideLoading()
              error = error || this.vatError || this.zipError;

              // only the last of these will be shown
              if (this.vatErrorAlert) {
                error = this.lang.partita_iva_format_error_completo;
              }
              if (this.zipErrorAlert) {
                error = this.lang.zip_format_error_completo;
              }
            // }
            if(error){
              return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
            }
          }
          this.backLabel = 'indietro';
          this.pageContent = 4;
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 2;
        }
        break;
      case 4: // pagina riepilogo dati fatturazione
        if(isForward){
          // Avvia salvataggio e il loading lo gestisce il metodo di salvataggio
          this.save();
          return;
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 3;
        }
        break;
      case 5: // salvataggio fatturazione
        if(isForward){
          if(this.data.selOrganization.login_need_default_payment_method){
            this.backLabel = 'annulla';
            this.pageContent = 7;
          }else{
            this.goToActivation();
          }
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 4;
          console.log(`case 5: this.pageContent`, this.pageContent)
        }
        break;
      case 6: // (PAGINA RIMOSSA) ringraziamento per salvataggio dati anagrafici
        if(isForward){
          if(this.data.selOrganization.login_need_default_payment_method){
            this.backLabel = 'indietro';
            this.pageContent = 7;
          }
          this.backLabel = 'indietro';
          this.pageContent = 7;
        }else{
          return this.closeConfirmation();
        }
        break;
      case 7: // pagine scelta inserimento carta di credito
        if(isForward){
          this.backLabel = 'indietro';
          if(this.paymentMethod === 'card'){
            this.pageContent = 8;
            ViewService.updateView.next(undefined);
            setTimeout(() => {
              let elements: any = this.stripe.elements();
              this.stripeCardElement = elements.create('card');
              this.stripeCardElement.mount('#card-element');
            }, 100);
          }else{
            // Caso skip metodo di pagamento
            this.goToActivation();
          }
        }else{
          return this.closeConfirmation();
        }
        break;
      case 8: // pagina inserimento carta di credito
        if(isForward){
          if(this.stripeCardAdded){
            this.backLabel = 'annulla';
            this.pageContent = 9;
          }else{
            this.insertCard();
          }
        }else{
          this.backLabel = 'annulla';
          this.pageContent = 7;
        }
        break;
      case 9: // pagina congratulazione carta inserita
        if(isForward){
          this.goToActivation();
        }else{
          return this.closeConfirmation();
        }
        break;
      case 10: // pagina inserimento imei e seriale
        if(isForward){
          this.checkFormError('imei', true);
          this.checkFormError('serial', true);
          const error: string = this.imeiError || this.serialError;
          if(error){
            return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
          }

          if(this.data.findParticipantByImei(this.imei)){
            this.backLabel = 'indietro';
            this.pageContent = 16;
          }else{
            this.backLabel = 'indietro';
            this.pageContent = 12;
          }


        }else{
          if(this.paymentMethod === 'skip'){
            this.backLabel = 'annulla';
            this.pageContent = 7;
          }else{
            return this.closeConfirmation();
          }
        }
        break;

      case 11: // pagina scelta tipo localizzatore da attivare -- DEPRECATA
        if(isForward){
          this.checkFormError('model', true);
          if(this.modelError){
            return this.view.modalAlert(this.lang.attenzione, this.modelError, 'warn', this.lang.ok);
          }
          this.backLabel = 'indietro';
          this.pageContent = 12;
        }else{
          this.backLabel = 'annulla';
          this.pageContent = 10;

        }
        break;

      case 12: // pagina inserimento dati veicolo
        if(isForward){
          this.checkFormError('vehicleName', true);
          this.checkFormError('label', true);
          this.checkFormError('odometer', true);
          const error: string = this.vehicleNameError || this.labelError || this.odometerError;
          if(error){
            return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
          }
          this.backLabel = 'indietro';
          this.pageContent = 13;
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 10;
        }
        break;
      case 13: // pagina di conferma attivazione tracker
        if(isForward){
          this.backLabel = 'indietro';
          this.pageContent = 14;
          this.activateTracker();
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 12;
        }
        break;
      case 14: // pagina loading attivazione dispositivo
        if(isForward){ // chiamata solo dopo api di attivazione
          if(this.activationError){
            this.backLabel = 'indietro';
            this.pageContent = 15;
          }else{
            this.backLabel = 'indietro';
            this.pageContent = 16;
          }
        }
        break;
      case 15: // pagina errori attivazione dispositivo
        if(isForward){
          this.backLabel = 'annulla';
          this.pageContent = 10;
        }else{
          this.backLabel = 'indietro';
          this.pageContent = 12;
        }
        break;
      case 16:
        if(this.fromActivation){
          this.navCtrl.navigateRoot('map')
          this.dismiss()
        }else{
          this.dismiss()
        }
        break;
      default:
        this.dismiss();
        break;
    }
    ViewService.updateView.next(undefined);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  myFunc() {
    console.log(`quiiiiiii`, )
  }

  // Effettua la verifica sul form
  async checkFormError(
    input: string,
    noTimeout?: boolean,
    lightCheck?: boolean,
  ) {
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'billingType':
        if(!this.billingType){
          this.billingTypeError = this.lang.necessaria_tipologia;
          ViewService.updateView.next(undefined);
          return;
        }
        this.billingTypeError = undefined;
        break;
      case 'billingLocation':
        if(!this.billingLocation){
          this.billingLocationError = this.lang.necessaria_location;
          ViewService.updateView.next(undefined);
          return;
        }
        this.billingLocationError = undefined;
        break;
      case 'name':
        if(!this.name){
          this.nameError = this.lang.necessario_nome;
          ViewService.updateView.next(undefined);
          return;
        }
        this.nameError = undefined;
        break;
      case 'surname':
        if(!this.surname){
          this.surnameError = this.lang.necessario_cognome;
          ViewService.updateView.next(undefined);
          return;
        }
        this.surnameError = undefined;
        break;
      case 'address':
        if(!this.address){
          this.addressError = this.lang.necessario_indirizzo;
          ViewService.updateView.next(undefined);
          return;
        }
        this.addressError = undefined;
        break;
      case 'zip':
        this.zipErrorAlert = null
        if(!this.zip){
          this.zipError = this.lang.necessario_cap;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.zipError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        if(this.billingLocation === 1 && !this.appComponent.isForcedAuth){ // Api verifica zip code solo se italia
          try {
            const res = await this.api.patchCheck_zip(
              this.data.selOrganization.me._id,
              {
                zip: this.zip,
              },
            );
            console.log(`res`, res);
            if (!res.isCorrect) {
              this.zipError = this.lang.zip_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`);
            this.zipError = this.lang.check_api_fail;
            this.zipErrorAlert = this.lang.zip_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }
        this.zipError = undefined;
        break;
      case 'city':
        if(!this.city){
          this.cityError = this.lang.necessaria_citta;
          ViewService.updateView.next(undefined);
          return;
        }
        this.cityError = undefined;
        break;
      case 'province':
        if(!this.province){
          this.provinceError = this.lang.necessario_provincia;
          ViewService.updateView.next(undefined);
          return;
        }
        this.provinceError = undefined;
        break;
      case 'companyName':
        if(!this.companyName){
          this.companyNameError = this.lang.necessario_nome_azienda;
          ViewService.updateView.next(undefined);
          return;
        }
        this.companyNameError = undefined;
        break;
      case 'vat':
        this.vatErrorAlert = null
        if(!this.vat){
          this.vatError = this.lang.necessario_piva;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.vatError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        //se billingLocation è italia allora procedo con il check
        if(this.billingLocation == 1 && !this.appComponent.isForcedAuth ){
          try {
            const res = await this.api.patchCheck_partita_iva(
              this.data.selOrganization.me._id,
              {
                partita_iva: this.vat,
              },
            )
            console.log(`res`, res)
            if (!res.isCorrect) {
              this.vatError = this.lang.partita_iva_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`, )
            this.vatError = this.lang.check_api_fail;
            this.vatErrorAlert = this.lang.partita_iva_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }

        this.vatError = undefined;
        break;
      case 'fiscalCode':
        this.fiscalCodeErrorAlert = null
        if(!this.fiscalCode){
          this.fiscalCodeError = this.lang.necessario_codice_fiscale;
          ViewService.updateView.next(undefined);
          return;
        }
        if (lightCheck) {
          this.fiscalCodeError = undefined;
          ViewService.updateView.next(undefined);
          return;
        }

        if(this.billingLocation === 1 && !this.appComponent.isForcedAuth){ // Controllo solo se italia
          try {
            const res = await this.api.patchCheck_codice_fiscale(
              this.data.selOrganization.me._id,
              {
                codice_fiscale: this.fiscalCode,
              },
            )
            console.log(`res`, res)
            if (!res.isCorrect) {
              this.fiscalCodeError = this.lang.codice_fiscale_format_error;
              ViewService.updateView.next(undefined);
              // the alert will be spawned by the caller func
              return;
            }
          } catch (e) {
            console.error(`e`, )
            this.fiscalCodeError = this.lang.check_api_fail;
            this.fiscalCodeError = this.lang.codice_fiscale_format_check_api_fail;
            ViewService.updateView.next(undefined);
            // the alert will be spawned by the caller func
            return;
          }
        }


        this.fiscalCodeError = undefined;
        break;
      case 'nation':
        if(!this.nation){
          this.nationError = this.lang.necessaria_nazione;
          ViewService.updateView.next(undefined);
          return;
        }
        this.nationError = undefined;
        break;
      case 'pec':
        if(!this.pec){
          this.pecError = this.lang.necessaria_pec;
          ViewService.updateView.next(undefined);
          return;
        }
        if(!Utility.validateEmail(this.pec)){
          this.pecError = this.lang.pec_invalida;
          ViewService.updateView.next(undefined);
          return;
        }
        this.pecError = undefined;
        break;
      case 'sdi':
        if(!this.sdi){
          this.sdiError = this.lang.necessario_sdi;
          ViewService.updateView.next(undefined);
          return;
        }
        this.sdiError = undefined;
        break;
      case 'model':
        if(!this.model){
          this.modelError = this.lang.necessario_modello;
          ViewService.updateView.next(undefined);
          return;
        }
        this.modelError = undefined;
        break;
      case 'imei':
        if(!this.imei){
          this.imeiError = this.lang.necessario_imei;
          ViewService.updateView.next(undefined);
          return;
        }
        this.imeiError = undefined;
        break;
      case 'serial':
        if(!this.serial){
          this.serialError = this.lang.necessario_seriale;
          ViewService.updateView.next(undefined);
          return;
        }
        this.serialError = undefined;
        break;
      case 'vehicleName':
        if(!this.vehicleName){
          this.vehicleNameError = this.lang.necessario_nome_veicolo;
          ViewService.updateView.next(undefined);
          return;
        }
        this.vehicleNameError = undefined;
        break;
      case 'label':
        if(this.label && (this.label <= 0 || this.label > 999)){
          this.labelError = this.lang.etichetta_invalida;
          ViewService.updateView.next(undefined);
          return;
        }
        this.labelError = undefined;
        break;
      case 'odometer':
        if(this.odometer && this.odometer < 0){
          this.odometerError = this.lang.chilometri_invalidi;
          ViewService.updateView.next(undefined);
          return;
        }
        this.odometerError = undefined;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  openSelect(event: any, type: string){
    switch (type) {
      case 'provinces':
        this.popoverProvinces(event);
        break;
      case 'nation':
        if(this.billingLocation !== 1){
          this.popoverNations(event);
        }
        break;
    }
  }

  // Apre scelta provincia
  async popoverProvinces(event: any) {
    let options: any[] = [];
    for(let prov of DataService.generalConfig.italy_province){
      options.push({
        id: prov.sigla,
        lbl: prov.nome,
        selected: this.province === prov.sigla,
      });
    }
    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: true
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.province = data.data.lbl;
        this.provinceCode = data.data.id;
        ViewService.updateView.next(undefined);
      }
      this.checkFormError('province');
    });
    await popover.present();
  }

  // Apre scelta nazione
  async popoverNations(event: any) {
    let options: any[] = [];

    let nationEligible: boolean;
    for (let nat of DataService.generalConfig.nations) {
      nationEligible = false;

      switch (this.billingLocation) {
        // europa
        case 2:
          // we don't want ITaly to be among eu nations
          if (nat.eu && nat.code !== "IT") {
            nationEligible = true;
          }
          break;
        // extra-europa
        case 3:
          if (!nat.eu) {
            nationEligible = true;
          }
          break;
      }

      if (nationEligible) {
        options.push({
          id: nat.code,
          lbl: nat.name,
          selected: this.nation === nat.name,
        });
      }

      // ? OLD code, remove?
      // if((this.billingLocation === 2 && nat.eu) || (this.billingLocation === 3 && !nat.eu)){
      //   options.push({
      //     id: nat.code,
      //     lbl: nat.name,
      //     selected: this.nation === nat.name,
      //   });
      // }
    }

    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: true
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.nation = data.data.lbl;
        this.nationCode = data.data.id,
        ViewService.updateView.next(undefined);
      }
      this.checkFormError('nation');
    });
    await popover.present();
  }

  private async save(){
    this.pageContent = 5;
    ViewService.updateView.next(undefined);
    let organization: Organization = new Organization();
    organization._id = this.data.selOrganization._id;
    organization.billing_type = this.billingType;
    organization.billing_location = this.billingLocation;
    organization.billing_companyName = this.companyName;
    organization.billing_name = this.name;
    organization.billing_surname = this.surname;
    organization.billing_address = this.address;
    organization.billing_zip = this.zip;
    organization.billing_city = this.city;
    organization.billing_province = this.provinceCode || this.province;
    organization.billing_country = this.nationCode;
    // organization.billing_mail = this.email;
    organization.billing_phone = this.phone;
    organization.billing_vat = this.vat;
    organization.billing_mailPEC = this.pec;
    organization.billing_sdiCode_fatturaElettronica = this.sdi;
    organization.billing_codiceFiscale = this.fiscalCode;

    try{
      let res: any = await this.api.patchBillingUser(this.data.selOrganization.me._id, organization);
      this.data.selOrganization.setData(res);
      if(!this.fromActivation){
        this.view.modalAlert(this.lang.successo, this.lang.salvataggio_completato, 'succ', this.lang.ok);
        this.dismiss()
      }else{
        await DataService.api.getOrganization(this.data.selOrganization.me._id, this.data.selOrganization._id).then((res: any) => {
          this.data.selOrganization.setData(res);
          ViewService.updateView.next(undefined);
        }).catch((err: any) => {
          console.error(err);
        });
      }
      this.changePageContent(true);
    }catch(err){
      console.error(err);
      this.changePageContent(false);
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.impossibile_salvare, 'warn', this.lang.ok);
    }
  }

  // Avvia l'inserimento di una carta di credito
  async insertCard(){
    await this.view.showLoading();
    this.stripeCardAdded = undefined;
    this.stripeError = undefined;
    ViewService.updateView.next(undefined);
    try{
      const res: any = await this.api.getClientSecretStripe(this.data.selOrganization._id, this.data.selOrganization.me._id);
      if(res.client_secret){
        const stripeResp: any = await this.stripe.confirmCardSetup(res.client_secret, {payment_method: {card: this.stripeCardElement}});
        if (stripeResp.error) {
          console.error(stripeResp.error);
          this.stripeError = this.lang.stripe_errori[stripeResp.error.decline_code];
          if(!this.stripeError) this.stripeError = this.lang.stripe_errori[stripeResp.error.code];
          if(!this.stripeError){
            throw Error;
          }
        }else{
          this.stripeService.setDefaultPayment(stripeResp.setupIntent.payment_method).then(() => {
            this.stripeService.getDefaultPayment(stripeResp.setupIntent.payment_method)
          }).catch((err) => {
            throw err
          })

          this.stripeCardAdded = true;
          this.changePageContent(true);
        }
        ViewService.updateView.next(undefined);
      }else{
        throw Error;
      }
    }catch(err){
      console.error(err);
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.errore_generico, 'warn', this.lang.ok);
    }
    this.view.hideLoading();
  }

  // Attiva il localizzatore
  private async activateTracker(){
    try{
      const res: any = await this.api.postActivateTracker(this.data.selOrganization.me._id, this.imei, this.serial, this.vehicleName, this.label, this.odometer);
      this.activationError = undefined;
      this.activationErrorDescription = undefined;
      this.tracker = new Partecipant(res);

      // Invio evento di statistica a google
      if(DataService.activationImei && DataService.activationSerial){
        try{
          StatisticsService.sendLogEventGoogle('tracker_activation_end_wa', {imei: DataService.activationImei, email: this.data.selOrganization.me.email, payment_card: !this.data.selOrganization.login_need_default_payment_method}); // Invia evento se in attivazione
        }catch(err){
          console.error(err);
        }
      }

    }catch(err){
      console.error(err);
      switch (err.status) {
        case 452:
          this.activationError = this.lang.errori.dispositivo_non_trovato;
          this.activationErrorDescription = this.lang.errori.dispositivo_non_trovato_desc;
          break;
        case 453:
          this.activationError = this.lang.errori.dispositivo_non_attivabile;
          this.activationErrorDescription = this.lang.errori.dispositivo_non_attivabile_desc;
          break;
        case 454:
          this.activationError = this.lang.errori.dispositivo_occupato;
          this.activationErrorDescription = this.lang.errori.dispositivo_occupato_desc;
          break;
        default:
          this.activationError = this.lang.errori.attivazione_generico;
          this.activationErrorDescription = this.lang.errori.attivazione_generico_desc;
          break;
      }
    }
    this.changePageContent(true);
  }

  // skippa l'inserimento carta o apre la pagina di inserimento a seconda del tipo
  fastPaymentMethod(type: string){
    this.paymentMethod = type;
    this.changePageContent(true);
  }

  // Apre le info al click su una i
  openInfo(type: string){
    switch (type) {
      case 'vehicleName':
        return this.view.modalAlert(this.lang.info, this.lang.info_nome_veicolo, 'info', this.lang.ok);
      case 'label':
        return this.view.modalAlert(this.lang.info, this.lang.info_label, 'info', this.lang.ok);
      case 'odometer':
        return this.view.modalAlert(this.lang.info, this.lang.info_odometro, 'info', this.lang.ok);
    }
  }

  /** listener **/
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  openManual(){
    try{
      window.open(DataService.generalConfig.url_support, '_blank');
    }catch(err){
      console.error(err);
    }
  }

  // Richiede la conferma di uscita alla procedura di attivazione
  closeConfirmation(){
    if(this.fromActivation){
      this.view.presentAlert(this.lang.conferma, this.lang.conferma_esci_info, [{
          text: this.lang.annulla,
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: this.lang.conferma,
          handler: () => {
            this.dismiss();
          }
        }]
      )
    }else{
      this.dismiss();
    }
  }


  async openTrackerImeiDetail(type: number){

    if(!type){
      return
    }
    let image_src = '';
    switch(type){
      case 1:
        image_src = 'assets/img/IMEI-FMB020.png'
        break;
      case 2:
        image_src = 'assets/img/IMEI-FMB920.png'
        break;
      case 3:
        image_src = 'assets/img/IMEI-FMC920.png'
        break;
    }


    const modal = await this.modalCtrl.create({
      backdropDismiss: true,
      component: ImageDetailComponent,
      componentProps: {
        image_src: image_src
      },
      cssClass: "modal_alert",
    });
    await modal.present();
  }

}
