// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer {
  cursor: pointer;
}

.compt {
  padding: 10px 0;
}
.compt .opt {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.compt .opt:hover {
  background-color: var(--app-light);
}
.compt .opt .opt-body {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--app-mid);
  word-break: break-all;
  word-break: break-word;
}
.compt .opt .opt-body .ob-lbl {
  padding-right: 10px;
  font-weight: 500;
}
.compt .opt .opt-body .ob-lbl2 {
  padding-right: 10px;
  font-size: 14px;
}
.compt .opt .opt-icon {
  margin-left: 10px;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/popover-payment/popover-payment.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;AAAE;EAIE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AADJ;AALI;EACE,kCAAA;AAON;AADI;EACE,OAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,qBAAA;EACA,sBAAA;AAGN;AADM;EACE,mBAAA;EACA,gBAAA;AAGR;AADM;EACE,mBAAA;EACA,eAAA;AAGR;AACI;EACE,iBAAA;EACA,eAAA;AACN","sourcesContent":[".pointer {\n  cursor: pointer;\n}\n\n.compt {\n  padding: 10px 0;\n  .opt {\n    &:hover {\n      background-color:var(--app-light);\n    }\n    padding: 10px 20px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    .opt-body {\n      flex: 1;\n      display: flex;\n      flex-wrap: wrap;\n      align-items: center;\n      color: var(--app-mid);\n      word-break: break-all;\n      word-break: break-word;\n\n      .ob-lbl {\n        padding-right: 10px;\n        font-weight: 500;\n      }\n      .ob-lbl2 {\n        padding-right: 10px;\n        font-size: 14px;\n      }\n    }\n\n    .opt-icon {\n      margin-left: 10px;\n      font-size: 20px;\n    }\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
