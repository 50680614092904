import {DataService} from "src/app/services/common/data.service";
import {ShopProducts} from "src/app/classes/common/shop-products";
import {Injectable} from "@angular/core";
import {ApiService} from "src/app/services/common/api.service";
import {Invoice} from "src/app/classes/common/invoice";
import {Subject} from "rxjs";
import {ShippingAddress} from "src/app/classes/common/order";
import {InvoiceLine} from "src/app/classes/common/invoiceLine";
import {NavController} from "@ionic/angular";
import {ViewService} from "src/app/services/common/view.service";
import {AuthService} from "src/app/services/common/auth.service";


@Injectable({
  providedIn: 'root'
})
export class ShopService{
  public list_product: (ShopProducts | any)[];
  public organization_list_product: any[];
  public lines_mapping: any[] = [];
  public shop_invoice: Invoice;
  public shipping_address: ShippingAddress = new ShippingAddress();
  public recent_shipping_address: ShippingAddress[] = [];
  public url_terms_of_sales: string;
  public accept_terms_of_sales: boolean = false;

  static setLoader = new Subject<any>();
  static setLoader$ = ShopService.setLoader.asObservable()

  static removeLoader = new Subject<any>();
  static removeLoader$ = ShopService.removeLoader.asObservable()

  public generalLoader: boolean = false;
  public productLoader: any[] = [];
  public invoiceLoader: boolean = false;
  public recapLoader: boolean = false;
  public canFinalize: boolean = false;
  public hasProduct: boolean = false;
  public isLogged: boolean = false;

  constructor(
    private data: DataService,
    private api: ApiService,
    private navController: NavController,
  ){}

  async inizialize(){
    try{
      await AuthService.isReady()
      await DataService.isGeneralConfig().then(async () => {
        this.list_product = DataService.generalConfig.elenco_prodotti_shop
        this.url_terms_of_sales = DataService.generalConfig.url_terms_of_sales;
        if (AuthService.isLogged){
          this.isLogged = true;
          DataService.isInitialized().then(async ()=>{
            this.organization_list_product = this.data.selOrganization.elenco_prodotti_shop
            await this.getShopInvoice()
            await this.analizeInvoiceLine()
            await this.getRecentOrder()
            await this.manageDisplayPrice()
          })
        }else{
          await this.manageDisplayPrice()
        }
      })

      ViewService.updateView.next(undefined);
    }catch (err){
      console.error(err)
    }
  }

  async clearData(){
    this.recent_shipping_address = []
    this.shop_invoice = undefined
    this.organization_list_product = []
    this.shipping_address = new ShippingAddress()
    await this.manageDisplayPrice()
    await this.analizeInvoiceLine()
  }


  /**
   * Verifico se è presente una shop invoice, altrimenti ne creo una
   */
  async getShopInvoice(invoice?: any, forceInvoiceCreation?: boolean ){
    ShopService.setLoader.next({target: 'invoice'})

    if(invoice){
      this.shop_invoice = this.mapInvoiceLineWithProduct(new Invoice(invoice, {lang: this.data.selOrganization.translate_language}))
      this.canFinalize = this.manageEnableFinalizeShopInvoice()
      ShopService.removeLoader.next({target: 'invoice'})
      return;
    }
    //manage invoice
    if(this.data.selOrganization.last_shop_invoice && !forceInvoiceCreation){
       await this.api.shopGetInvoice(this.data.selOrganization.me._id, this.data.selOrganization.last_shop_invoice).then((res: any)=>{
         if(res.success && res.invoice){
            if(res.invoice.status === 'draft'){
              this.shop_invoice = this.mapInvoiceLineWithProduct(new Invoice(res.invoice,{lang: this.data.selOrganization.translate_language}))
            }else{
              this.createShopInvoice()
            }
         }
       }).catch((err)=>{
         console.error(err)
       })
    }else{
      //non ho trovato l'invoice, devo crearne una
      await this.createShopInvoice()
    }
    this.canFinalize = this.manageEnableFinalizeShopInvoice()
    ShopService.removeLoader.next({target: 'invoice'})
  }

  async createShopInvoice(){
    await this.api.shopCreateInvoice(this.data.selOrganization.me._id).then((res: any)=>{
      console.log(res)
      if(res.success && res.invoice){
        this.shop_invoice = this.mapInvoiceLineWithProduct(new Invoice(res.invoice, {lang: this.data.selOrganization.translate_language}))
      }
    }).catch((err)=>{
      console.error(err)
    })
  }

  /**
   * Scelgo il prezzo da visualizzare per ogni singolo prodotto
   */
  async manageDisplayPrice(){

    if(this.list_product){ // ho un elenco prodotti

      for(let i=0; i<this.list_product.length; i++){

        let custom_product_price: string;

        if(this.organization_list_product && this.organization_list_product[i]){ //il cliente ha una personalizzazione per questo prodotto?
          //devo cercare il price X in custom_priceId_list
          custom_product_price = this.organization_list_product[i].price_id
          this.list_product[i]._is_displayed = this.organization_list_product[i].can_sell;
        }

        //scelgo che prezzo visualizzare per i prodotti rimasti
        for(let product of this.list_product[i].custom_priceId_list){
          if(product.is_default){
            this.list_product[i]._default_price = {price: product.price, price_id: product.price_id}
          }
          if(custom_product_price && custom_product_price === product.price_id){
            // ecco il prezzo custom
            // custom_price={price_id: product.price_id, price: product.price}
            this.list_product[i].price_id = product?.price_id
            this.list_product[i]._price = product?.price
            this.list_product[i]._has_custom_price = true;
          }
        }

        if(!this.list_product[i]._has_custom_price){
          this.list_product[i].price_id = this.list_product[i]._default_price?.price_id
          this.list_product[i]._price = this.list_product[i]._default_price?.price
          this.list_product[i]._has_custom_price = false;
        }

        if(this.list_product[i].type === 3){
          this.list_product[i]._is_displayed = false;
        }

      }
      if(this.list_product.find(elem => elem._is_displayed === true)){
        this.hasProduct = true;
      }else{
        this.hasProduct = false;
      }

      //sort dei prodotti in base al type
      // if(this.list_product.length){
      //   this.list_product.sort((a, b) => a.type - b.type);
      // }
    }
    ViewService.updateView.next(undefined);
  }

  /**
   * Analizza l'invoice shop e lega le line con i price id dei prodotti
   */
  async analizeInvoiceLine(){
    if(this.shop_invoice){
      this.lines_mapping = []
      let new_line = []
      //check the line
      for(let line of this.shop_invoice.lines){
        //adesso devo trovare l'id di questa line dentro l'elenco prodotti
        for(let i = 0; i<this.list_product.length; i++){
          for(let j =0; j<this.list_product[i].custom_priceId_list.length; j++){
            if(this.list_product[i].custom_priceId_list[j].price_id === line.price.id){
              new_line[i]={price: line.price.id, quantity: line.quantity, item_id: line.invoice_item}; //mappo le line della fattura l'identificativo dei prodotti
            }
          }
        }
      }
      if(new_line){
        this.lines_mapping = new_line
      }
    }else{
      this.lines_mapping = []
    }
  }

  async addItemToInvoice(price_id){
    try{
      if(price_id){
        let addItem: any = await this.api.shopAddItemToInvoice(this.data.selOrganization.me._id, price_id, 1)
        if(addItem){
          await this.getShopInvoice(addItem.invoice)
          await this.analizeInvoiceLine()
        }else{
          //show error message
          console.error(addItem)
        }
      }
    }catch(err){
      console.error(err)
    }
    return;
  }

  async updateInvoiceItem(invoice_item, quantity){
    try{
      if(invoice_item){
        let addItem:any = await this.api.shopPatchInvoiceItem(this.data.selOrganization.me._id, invoice_item, quantity)
        if(addItem){
          await this.getShopInvoice(addItem.invoice)
          await this.analizeInvoiceLine()
        }else{
          //show error message
          console.error(addItem)
        }
      }
    }catch(err){
      console.error(err)
    }
    return;
  }

  async deleteInvoiceItem(invoice_item){
    try{
      if(invoice_item){
        await this.api.shopDeleteInvoiceItem(this.data.selOrganization.me._id, invoice_item).then(async (res: any)=>{
          await this.getShopInvoice(res.invoice)
          await this.analizeInvoiceLine()
        }).catch((err)=>{
          console.error(err)
        })
      }
    }catch(err){
      console.error(err)
    }
  }

  /**
   * Metodo utilizzato per ottenere gli ordini recenti
   */
  async getRecentOrder(){
    await this.api.shopGetRecentOrder(this.data.selOrganization.me._id, 4).then(async (res: any)=>{
      //alimentare variabile recent Address con gli indirizzi degli orders
      this.recent_shipping_address = []
      if(res.success){
        for(let i=0; i<=res.orders.length; i++){
          if(!res.orders[i]?.shipping_address){
            break;
          }
          for(let j=i+1; j<=res.orders.length - 1; j++){
            //sto analizzando tutti i singoli elementi dell'array
            if(res.orders[j]?.shipping_address && JSON.stringify(res.orders[i].shipping_address) === JSON.stringify(res.orders[j].shipping_address)){
              //rimuovo dall'array l'elemento i
              delete res.orders[i]
              break;
            }
          }
        }

        res.orders.map((elem)=>{
          //è presente, bene allora controllo se è uguale
          this.recent_shipping_address.push(new ShippingAddress(elem.shipping_address));

        })
      }
    })
    .catch((err)=>{
      console.error(err)
    })
  }

  /**
   * Assegnare alle singole line il name e il type di elenco_prodotti
   */
  mapInvoiceLineWithProduct(invoice: Invoice){
    if(this.list_product && invoice.metadata?.type ==='product'){
      let newLines = [];
      for(let item of invoice.lines){
        //devo andare a cercare questo price
        this.list_product.forEach((prodotto, i)=>{
          if(prodotto.custom_priceId_list){
            for(let custom_list of prodotto.custom_priceId_list){
              if(custom_list.price_id === item.price.id){
                newLines.push(new InvoiceLine({...item, ...{prodotto_associato: prodotto}}, {lang: this.data.selOrganization.translate_language}))
              }
            }
          }
        })
      }
      invoice.lines = newLines;
    }
    return invoice
  }

  /**
   * Metodo che gestisce l'attivazione del bottone per proseguire con la finalizzazione dell'ordine
   */
  manageEnableFinalizeShopInvoice(){
    //ottengo la shop invoice
    if(this.shop_invoice?.lines){
      for(let line of this.shop_invoice.lines){
        //1. Quanto aggiunto alla fatture un prodotto con tipo !== da spedizione
        if(line._prodotto_associato?.type !== 3){
          return true;
        }
      }
    }
    return false;
  }

  goToPage(pageName: string){
    this.navController.navigateForward(pageName);
  }

}
