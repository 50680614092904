import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import {
  MenuController,
  ModalController,
  NavController
} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {
  ViewService
} from "../../../services/common/view.service";
import {
  AuthService
} from "../../../services/common/auth.service";
import {
  DataService
} from "../../../services/common/data.service";
import {PopoverController} from "@ionic/angular";
import {PopoverFleetComponent} from "src/app/components/common/popover-fleet/popover-fleet.component";
import {verifySupportedTypeScriptVersion} from "@angular/compiler-cli/src/typescript_support";
import {environment} from "src/environments/environment";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements OnInit {

  @Input() currentPath: string;

  lang: any = {};


  navButtons2 = [
    {
      label: "mappa",
      icon: "mapPin",
      path: "map",
    },
    {
      label: "percorsi",
      icon: "path",
      path: "routes-history",
    },
    {
      label: "report",
      icon: "reportSearch",
      path: "report",
    },
    {
      label: "scadenziario",
      icon: "schedule",
      path: "deadline",
      platform: 2
    },
    {
      label: "allarmi",
      icon: "alarm",
      path: "alarms",
    },
    {
      label: "notifiche",
      icon: "bell",
      path: "notifications",
      unread: true,
    },{
      label: "acquista",
      icon: "cart",
      path: "shop",
    },
    {
      label: "attivazione",
      icon: "addBtn",
      path: "activation"
    }
  ];

  navButtons3 = [
    {
      label: "aiuto",
      icon: "help-double",
      path: "tickets",
      color: "warning"
    }
  ];

  sidebar_isEnabled: boolean;

  // listener
  listener = {
    view: null
  };

  constructor(
    private cd: ChangeDetectorRef,
    private navCtrl: NavController,
    public view: ViewService,
    private menuCtrl: MenuController,
    private auth: AuthService,
    private translate: TranslateService,
    public data: DataService,
    private popoverCtrl: PopoverController,
  ) {
  }

  async ngOnInit() {
    this.listenerView();
    ViewService.updateView.next(undefined);
    await DataService.isInitialized();

    for(let i=0; i<this.navButtons2.length; i++){
      if(this.navButtons2[i].platform){
        if(environment.production){
          if(this.data.selOrganization.platform_type !== this.navButtons2[i].platform) this.navButtons2.splice(i, 1);
        }
      }
    }

    setTimeout(() => {
      this.translate.get('TOPBAR').subscribe((lang: any) => {
        this.lang = lang;
      });
    }, 1500);
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async openPage(path: string) {
    if(!this.checkLogin()) return;
    // if(path === 'setup-gps'){
    //   window.open(DataService.generalConfig.url_activation, 'blank');
    //   return;
    // }
    // if(path === 'shop'){
    //   window.open(DataService.generalConfig.url_buy, 'blank');
    //   return;
    // }
    if (path && path != this.currentPath) {
      console.log("openPage() path", path);
      this.navCtrl.navigateRoot("/" + path);
    }
  }

  // Verifica se son loggato, se non lo sono visualizza un alert (per pagina shop quando aperta da non loggato)
  private checkLogin(){
    if(!AuthService.isLogged){
      const buttons: any[] = [
        {
          label: this.lang.chiudi,
          color: 'danger'
        },
        {
          label: this.lang.vai_al_login,
          click: () => {
            this.navCtrl.navigateRoot("/login");
          }
        }
      ];
      this.view.modalAlert(this.lang.attenzione, this.lang.devi_essere_loggato, 'warn', buttons);
      return false;
    }
    return true;
  }

  /** listener **/

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.set_sidebar_isEnabled();
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }


  async set_sidebar_isEnabled() {
    this.sidebar_isEnabled = await this.menuCtrl.isEnabled("sidebar");
    this.cd.markForCheck();
  }

  toggleSidebar() {
    if(!this.checkLogin()) return;
    this.menuCtrl.toggle("sidebar");
  }

  async toggleFleetPopup(event){

    const popover =
      await this.popoverCtrl.create({
        backdropDismiss: true,
        component: PopoverFleetComponent,
        componentProps: {},
        cssClass: "popover_fleet",
        event: event,
        showBackdrop: false,
      });

    await popover.present();
  }

  logout(){
    this.auth.logout();
  }





  // ---- Docs


  /**
   * example of an alert behaving more like a modal
   * - remains after opening a modal ("AddMembersNewGroup")
   * - dismisses on that modal's completion
   */

  // alertNewGroup()  NON togliere
  /*async alertNewGroup() {
   const alert = await this.alertCtrl.create({
   header: 'Group name',
   inputs: [
   {
   name: 'groupName',
   // placeholder: 'Group name',
   type: 'text',
   },
   ],

   buttons: [
   {
   text: 'Cancel',
   role: 'cancel',
   // cssClass: 'warn_000',
   // handler: () => {}
   },

   {
   text: 'Next',
   // cssClass: 'blue_fff',
   handler: (data: any) => {
   console.log(data);

   if (data && data.groupName) {
   this.openAddMembersNewGroup(data.groupName);
   }

   return false; // don't dismiss the alert
   }
   },
   ]

   });


   //noinspection TypeScriptUnresolvedFunction
   await alert.present();
   this.alert_newGroup = alert;
   }*/

  // openAddMembersNewGroup()  NON togliere
  /*async openAddMembersNewGroup(groupName: string) {
   console.log("openAddMembersNewGroup()")

   const modal = await this.modalCtrl.create({
   component: AddMembersNewGroupPage,
   componentProps: {
   navData: {
   groupName: groupName,
   }
   },
   // backdropDismiss: false,
   // showBackdrop: false,
   cssClass: "modal_listTallThin",
   });


   //noinspection TypeScriptUnresolvedFunction
   await modal.present();


   //noinspection TypeScriptUnresolvedFunction
   const { data } = await modal.onWillDismiss();
   // console.log(data);

   if (data) {
   if (data.dismissAlert) {
   this.alert_newGroup.dismiss();
   }
   }

   }*/











}
