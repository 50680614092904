import { Component, OnInit } from '@angular/core';
import {Input} from "@angular/core";
import {Invoice} from "src/app/classes/common/invoice";
import {ShopService} from "src/app/services/common/shop.service";
import {ChangeDetectorRef} from "@angular/core";
import {IonicSlides, ModalController} from "@ionic/angular";
import {ViewService} from "src/app/services/common/view.service";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "src/app/services/common/data.service";
import {NavController} from "@ionic/angular";
import {ApiService} from "src/app/services/common/api.service";
import {StripeService} from "src/app/services/common/stripe.service";
import {Validators, FormGroup, FormControl} from "@angular/forms";
import {ShippingAddress} from "src/app/classes/common/order";
import {Navigation, } from "swiper/types/modules";

@Component({
  selector: 'app-finalize-order',
  templateUrl: './finalize-order.page.html',
  styleUrls: ['./finalize-order.page.scss'],
})
export class FinalizeOrderPage implements OnInit {
  @Input() shopInvoice: Invoice;
  @Input() shopService: ShopService;

  swiperModules = [IonicSlides];
  swiperConfig = {
    pagination: { clickable: true }, // Abilita i pallini della paginazione
    navigation: true, // Aggiungi frecce se necessario
    speed: 400, // Velocità di transizione
    slidesPerView: 2.4, // Numero di slide visibili
    centeredSlides: true, // Simula un "mezzo slide" iniziale
    initialSlide: 0, // Slide iniziale (senza frazioni in Swiper)
  };

  public shippingAddressForm: FormGroup;

  pageContent = 1;
  slideOpts: any = {initialSlide: 0.5,speed: 400, slidesPerView: 2.4}

  steps = [
    "SHOP.indirizzo_spedizione_lbl",
    "SHOP.pagamento",
    "SHOP.conferma",
  ];
  lang: any;
  // listener
  listener = {
    view: null,
  };
  payment_method: number;
  public showPayButton: boolean = true;
  public addNewPayment: boolean = false;
  public selectedPayment: any;
  public loaderPayment: boolean;
  public successsResponse: boolean = false;
  public errorResponse: boolean = false;

  public canFinalizeBefore: boolean = false; //variabile utilizzata nel caso di fatture con amount due a 0, qunindi possono essere finalizzate subito

  constructor(
    private ref: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private view: ViewService,
    private translate: TranslateService,
    public data: DataService,
    private navController: NavController,
    private api: ApiService,
    public stripeService: StripeService
  ) { }

  async ngOnInit() {
    this.stripeService.inizialize_lite();
    this.shippingAddressForm = new FormGroup({
      name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      address1: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      city: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      zipcode: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      state: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      country: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      phone: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.pattern('[- +()0-9]+')]
      }),
      id: new FormControl(null, {
        updateOn: 'change'
      }),
    });
    if(this.shopInvoice.amount_due === 0 ){
      this.canFinalizeBefore = true;
    }
  }

  dismiss(data: any){
    this.modalCtrl.dismiss(data);
  }

  async changePageContent(isForward: boolean){
    ViewService.updateView.next(undefined)

    switch(this.pageContent){
      case 1: //Pagina indirizzo di spediizone

        if(isForward){
          this.updateShippingAddress()
          if(this.canFinalizeBefore){
            await this.finalizeShopInvoice()
            this.pageContent = 3
          }else{
            this.pageContent = 2
          }
        }else{
          this.dismiss({});
        }
        break;
      case 2: //pagina modalita di pagamento
        if(isForward){
          //chiamata api per pagare la fattura
          if(this.addNewPayment){
            await this.confirmCardSetup()
          }
          await this.finalizeShopInvoice()
          this.pageContent = 3
        }else{
          if(this.payment_method){
            this.pageContent = 2
            this.payment_method = undefined;
          }else{
            this.pageContent = 1
            this.payment_method = undefined;
          }
        }
        break;
      case 3: //Pagina conferma
        this.dismiss({payment: true});
        break;
    }

    ViewService.updateView.next(undefined);
  }

  updateShippingAddress(){
    this.shopService.shipping_address = new ShippingAddress(this.shippingAddressForm.value)
  }

  managePaymentAction(obj){
    if(obj){
      switch(obj.type){
        case 'btn':
          this.showPayButton = obj.value
          break;
      }
    }
  }

  changeSelectedPayment(selectedPayment: string){
    this.selectedPayment = selectedPayment ? selectedPayment : this.stripeService.default_payment_method
  }

  noValidPaymentMethod(obj){ //event emitter su presenza/mancanza metodi di pagamento
    if(obj){
      if(obj.validPayment === false){ //non ho metodi di pagamento validi
        this.addNewPayment = true;
        this.showPayButton = true;
        this.createElement();
      }else{
        this.addNewPayment = false;
        this.showPayButton = true;
      }
    }
  }

  async finalizeShopInvoice(){

    this.loaderPayment = true;
    await this.api.shopFinalizeInvoice(this.data.selOrganization.me._id, this.selectedPayment, this.payment_method === 2, this.shopService.shipping_address, ViewService.isMobile).then((res: any)=>{
      if(res.success){
        this.successsResponse = true;
        this.shopInvoice = new Invoice(res.invoice)
      }
    }).catch((err)=>{
      this.errorResponse = true;

    })
    this.loaderPayment = false;
  }


  setShippingAddress(shipping_address: ShippingAddress, id: number){
    this.shopService.shipping_address = new ShippingAddress({...shipping_address, ...{id}});

    if(this.shippingAddressForm){
      this.shippingAddressForm.get('name').setValue(this.shopService.shipping_address.name);
      this.shippingAddressForm.get('address1').setValue(this.shopService.shipping_address.address1);
      this.shippingAddressForm.get('city').setValue(this.shopService.shipping_address.city);
      this.shippingAddressForm.get('zipcode').setValue(this.shopService.shipping_address.zipcode);
      this.shippingAddressForm.get('state').setValue(this.shopService.shipping_address.state);
      this.shippingAddressForm.get('country').setValue(this.shopService.shipping_address.country);
      this.shippingAddressForm.get('phone').setValue(this.shopService.shipping_address.phone);
      this.shippingAddressForm.get('id').setValue(this.shopService.shipping_address.id);
    }
  }
  createElement(){
    this.showPayButton = false;
    let showPayButton, complete = false
    switch(this.payment_method){
      case 1:
        setTimeout(() => {
          //create card
          this.stripeService.createElement('card')
          ViewService.updateView.next(undefined)
          // Handle real-time validation errors from the card Element.
          this.stripeService.stripePaymentElement.addEventListener('change', (event) =>{
            var displayError = document.getElementById('error-message');
            if (event.error) {
              showPayButton = false
              displayError.textContent = event.error.message;
            } else {
              showPayButton = true
              displayError.textContent = '';
            }

            if(event.complete){
              complete=true
            }
            this.showPayButton = showPayButton && complete
          });

        }, 600);
        break;
    }
  }

  /**
   * Aggiungo un nuovo metodo di pagamento
   */
  async confirmCardSetup(){
    this.loaderPayment =  true
    //procedo aaggiungere il nuovo metodi di pagaemnto
    await this.stripeService.stripe.confirmCardSetup(this.stripeService.customer_secret, {
      payment_method: {
        card: this.stripeService.stripePaymentElement,
        billing_details: {
          name: this.data.selOrganization.billing_name,
          email: this.data.selOrganization.me.email,
        }
      }
    }).then(async (res)=>{
      if(res.error){
        //chiudo la modale di finalizzazione ordine
        this.modalCtrl.dismiss()
        //l'aggiunta del metodo di pagamento non è andata a buon fine.
        await this.view.modalAlert(this.translate.instant('PAYMENT.alert.errore_pagamento'), this.translate.instant('PAYMENT.alert.pagamento_non_riuscito'), 'warn', this.translate.instant('PAYMENT.alert.ok'));
        return false;
      }else{
        //Devo ottenere il metodo di pagamento che è stato appena aggiunto
        this.selectedPayment = res.setupIntent.payment_method;
        this.stripeService.setDefaultPayment(this.selectedPayment).then(() => {
          this.stripeService.getDefaultPayment(this.selectedPayment)
        }).catch((err) => {
          console.error(err)
          return false;
        })
      }
    }).catch(async (err)=>{
      this.modalCtrl.dismiss()
      //mostro modale di errore
      await this.view.modalAlert(this.translate.instant('PAYMENT.alert.errore_pagamento'), this.translate.instant('PAYMENT.alert.pagamento_non_riuscito'), 'warn', this.translate.instant('PAYMENT.alert.ok'));
      return false;
    })

  }

  async goToPage(url: string){
    this.dismiss({payment: true});
  }

}
