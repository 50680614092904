import { Component, OnInit } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormControl} from "@angular/forms";
import {Validators} from "@angular/forms";
import {DataService} from "src/app/services/common/data.service";
import {StripeService} from "src/app/services/common/stripe.service";
import {ShopService} from "src/app/services/common/shop.service";
import {ViewService} from "src/app/services/common/view.service";
import {TranslateService} from "@ngx-translate/core";
import {Input} from "@angular/core";
import {Output} from "@angular/core";
import {EventEmitter} from "@angular/core";


@Component({
  selector: 'app-shipping-data',
  templateUrl: './shipping-data.component.html',
  styleUrls: ['./shipping-data.component.scss'],
})
export class ShippingDataComponent implements OnInit {
  @Input() setShippingData;
  @Input() btnLabel: string;
  @Input() loaderBtn: boolean;
  @Output() addressUpdated = new EventEmitter<any>();
  public shippingAddressForm: FormGroup;
  lang: any;

  constructor(
    public data: DataService,
    public stripeService: StripeService,
    public shopService: ShopService,
    private view: ViewService,
    private translate: TranslateService,
  ) { }


  ngOnInit() {

    this.shippingAddressForm = new FormGroup({
      name: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      address1: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      city: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      zipcode: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      state: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      country: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(2)]
      }),
      phone: new FormControl(null, {
        updateOn: 'change',
        validators: [Validators.required, Validators.pattern('[- +()0-9]+')]
      }),
      id: new FormControl(null, {
        updateOn: 'change'
      })

    });
    this.translate.get('USER_DATA').subscribe((lang: any) => {
      this.lang = lang;
    });

    if(this.shopService.shipping_address?.id){
      this.shippingAddressForm.get('name').setValue(this.shopService.shipping_address.name);
      this.shippingAddressForm.get('address1').setValue(this.shopService.shipping_address.address1);
      this.shippingAddressForm.get('city').setValue(this.shopService.shipping_address.city);
      this.shippingAddressForm.get('zipcode').setValue(this.shopService.shipping_address.zipcode);
      this.shippingAddressForm.get('state').setValue(this.shopService.shipping_address.state);
      this.shippingAddressForm.get('country').setValue(this.shopService.shipping_address.country);
      this.shippingAddressForm.get('phone').setValue(this.shopService.shipping_address.phone);
      this.shippingAddressForm.get('id').setValue(this.shopService.shipping_address.id);
    }
  }

  async saveAddress(){
    await this.view.hideLoading()
    await this.view.showLoading(this.translate.instant('FREE_TRIAL.salvataggio_indirizzo_in_corso'))
    try{
      this.addressUpdated.emit({success: true, data: this.shippingAddressForm.value})
      await this.view.hideLoading()
    }catch(err){
      await this.view.hideLoading()
    }
  }

}
