import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "./services/common/data.service";
import {AuthService} from "./services/common/auth.service";
import {ApiService} from "./services/common/api.service";
import {ViewService} from "./services/common/view.service";
import {GoogleMapsService} from "./services/common/google-map.service";
import {AlertController, ModalController, NavController, Platform} from "@ionic/angular";
import {StatisticsService} from "./services/common/statistics.service";
import * as moment from 'moment';
import 'moment-timezone';
import {MainMapService} from "./services/common/main-map-service";
import {Utility} from "./classes/common/utility";
import {NavigationExtras} from "@angular/router";
import {Storage} from '@ionic/storage-angular';
import {environment} from "../environments/environment";
import {UserDataPage} from "./pages/desktop/common/user-data/user-data.page";
import {PaymentProblemsPage} from "./pages/desktop/common/payment-problems/payment-problems.page";

declare let firebase: any;
declare let google: any;
declare let OverlappingMarkerSpiderfier: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  version: string = environment.version;

  static iconsGreen: any = {
    0: {min: 337.5, max: 22.5, url: 'assets/img/markers/marker-green-0.png', url_in1_1: 'assets/img/markers/marker-green-0-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-0-in1_0.png'},
    45: {min: 22.5, max: 67.5, url: 'assets/img/markers/marker-green-45.png', url_in1_1: 'assets/img/markers/marker-green-45-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-45-in1_0.png'},
    90: {min: 67.5, max: 112.5, url: 'assets/img/markers/marker-green-90.png', url_in1_1: 'assets/img/markers/marker-green-90-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-90-in1_0.png'},
    135: {min: 112.5, max: 157.5, url: 'assets/img/markers/marker-green-135.png', url_in1_1: 'assets/img/markers/marker-green-135-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-135-in1_0.png'},
    180: {min: 157.5, max: 202.5, url: 'assets/img/markers/marker-green-180.png', url_in1_1: 'assets/img/markers/marker-green-180-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-180-in1_0.png'},
    225: {min: 202.5, max: 247.5, url: 'assets/img/markers/marker-green-225.png', url_in1_1: 'assets/img/markers/marker-green-225-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-225-in1_0.png'},
    270: {min: 247.5, max: 292.5, url: 'assets/img/markers/marker-green-270.png', url_in1_1: 'assets/img/markers/marker-green-270-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-270-in1_0.png'},
    315: {min: 292.5, max: 337.5, url: 'assets/img/markers/marker-green-315.png', url_in1_1: 'assets/img/markers/marker-green-315-in1_1.png', url_in1_0: 'assets/img/markers/marker-green-315-in1_0.png'}
  };

  static iconsRed: any = {
    0: {min: 337.5, max: 22.5, url: 'assets/img/markers/marker-red-0.png', url_in1_1: 'assets/img/markers/marker-red-0-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-0-in1_0.png'},
    45: {min: 22.5, max: 67.5, url: 'assets/img/markers/marker-red-45.png', url_in1_1: 'assets/img/markers/marker-red-45-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-45-in1_0.png'},
    90: {min: 67.5, max: 112.5, url: 'assets/img/markers/marker-red-90.png', url_in1_1: 'assets/img/markers/marker-red-90-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-90-in1_0.png'},
    135: {min: 112.5, max: 157.5, url: 'assets/img/markers/marker-red-135.png', url_in1_1: 'assets/img/markers/marker-red-135-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-135-in1_0.png'},
    180: {min: 157.5, max: 202.5, url: 'assets/img/markers/marker-red-180.png', url_in1_1: 'assets/img/markers/marker-red-180-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-180-in1_0.png'},
    225: {min: 202.5, max: 247.5, url: 'assets/img/markers/marker-red-225.png', url_in1_1: 'assets/img/markers/marker-red-225-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-225-in1_0.png'},
    270: {min: 247.5, max: 292.5, url: 'assets/img/markers/marker-red-270.png', url_in1_1: 'assets/img/markers/marker-red-270-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-270-in1_0.png'},
    315: {min: 292.5, max: 337.5, url: 'assets/img/markers/marker-red-315.png', url_in1_1: 'assets/img/markers/marker-red-315-in1_1.png', url_in1_0: 'assets/img/markers/marker-red-315-in1_0.png'}
  };

  originalUrl: any;

  currentPath: string;
  menuVisible: boolean = true;

  isDev: boolean;
  isForcedAuth: boolean;

  widthAlert: any;
  private flag_view_check: boolean;

  constructor(
    private platform: Platform,
    public auth: AuthService,
    private navController: NavController,
    public api: ApiService,
    private translate: TranslateService,
    public view: ViewService,
    private ref: ChangeDetectorRef,
    public data: DataService,
    private googleMap: GoogleMapsService,
    private modalController: ModalController,
    private storage: Storage,
    private alertController: AlertController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      try{

        firebase.initializeApp(environment.firebaseConfig);
        this.originalUrl = environment.originalUrl;
        /** Lettura parametri per attivazione tracker **/
        DataService.activationImei =  this.originalUrl.searchParams.get("activationImei");
        DataService.activationSerial =  this.originalUrl.searchParams.get("activationSerial");
        DataService.activationModel =  this.originalUrl.searchParams.get("activationModel");
        DataService.registrationEmail =  this.originalUrl.searchParams.get("registrationEmail");

        /** Parametri per autenticazione ticket */
        const c_phone: string = this.originalUrl.searchParams.get("customerPhone");
        const o_phone: string = this.originalUrl.searchParams.get("ownerPhone");
        if(c_phone) DataService.customerPhone = `+${c_phone}`;
        if(o_phone) DataService.ownerPhone = `+${o_phone}`;

        /** Settaggio parametri/azioni autenticazione **/
        AuthService.forcedEmail =  this.originalUrl.searchParams.get("forcedEmail");
        AuthService.forcedToken =  this.originalUrl.searchParams.get("forcedToken");
        AuthService.redirect =  this.originalUrl.searchParams.get("redirect");
        AuthService.openModal =  this.originalUrl.searchParams.get("openModal");

        /** Identificazione app **/
        ApiService.platformType = environment.platform_type || 1;

        /** Settaggio lingua app come lingua browser **/
        await this.setLang();

        /** Verifica se è necessario aprire la versione desktop **/
        this.checkWidthVersion();

        /** Inizializzazione statistiche **/
        StatisticsService.initialization(firebase, 'balin_desktop');
        if(DataService.activationImei && DataService.activationSerial){
          StatisticsService.sendLogEventGoogle('tracker_activation_start_wa', {imei: DataService.activationImei}); // Invia evento se in attivazione
        }

        /** Settaggio api url e modalità api **/
        this.api.setModality(environment.baseUrl, environment.communicationBaseUrl);

        /** Ottinimento dati general_parameter (firebase realtime database) **/
        const data: any = await this.api.getConfigInfo();
        DataService.generalConfig.setData(data, ApiService.platformType);
        if(!DataService.generalConfig) throw new Error('No general parameters found');
        DataService.hasGeneralConfig = true;
        DataService.onGeneralConfig.next(true)
        /** Inizializzazione service view con icone ed elementi specifici della piattaforma **/
        this.view.initialize({
          info: 'assets/img/icons-fdc/infoCircle_main-softer.svg',
          succ: 'assets/img/icons-fdc/check-circle_succ.svg',
          warn: 'assets/img/icons-fdc/alert-triangle_warn.svg'
        }, false);

        if(AuthService.forcedEmail && AuthService.forcedToken) this.isForcedAuth = true;

        /** Settaggio parametri per gestione autenticazione con token **/
        AuthService.authTokenInUrl = this.originalUrl.searchParams.get("authToken") || await this.storage.get('authToken')
        AuthService.authStateChange.subscribe(async (loggedIn: boolean) => {
          this.manageAuth(loggedIn);
        });

        /** Avvio operazioni di login tramite inizializzazione componente **/
        // await this.view.showLoading();
        this.auth.initialization(firebase, this.api);

        /** Verifico se sono in dev o prod **/
        this.checkProdDevMod();

        /** inizializzazione google maps **/
        this.googleMap.initialization(google, OverlappingMarkerSpiderfier);

        this.storage.remove('last_selected_device'); // Rimuove eventuali preselezioni

      }catch(err){
        this.manageStartUpError(err);
      }

      // this.statusBar.styleDefault();
      // this.statusBar.overlaysWebView(false);

    });
  }

  // Gestisce le azioni da eseguire dopo che firebase autenticazione si è inizializzato
  private async manageAuth(loggedIn: boolean){
    try{
      if(loggedIn) {
        await this.data.initialize(this.api, firebase);
        if(
          this.data.organizations.length === 1 &&
          !this.data.selOrganization.translate_language
        ) {
          await this.storage.get("organization_language").then(async (lang: any) => {
            if(lang){
              lang = {code: lang};
            }else{
              lang = {code: 'it'};
            }
            let timezone: string = moment.tz.guess(true) || "Europe/Rome";
            let tz: any = DataService.generalConfig.timezones.find((elem: any) => {
              return elem === timezone;
            });
            if(!tz) timezone = "Europe/Rome";
            await this.api.patchOrganization(this.data.selOrganization._id, this.data.selOrganization.me._id, lang.code, timezone, undefined, true).then((res: any) => {
              this.data.selOrganization.setData(res);
              console.log('patch organization ok');
            }).catch((err: any) => {
              console.error('path organization', err);
            })
          });
        }
        // * set app language
        await this.setLang({
          langCode: this.data.selOrganization.translate_language,
        });

        this.checkPaymentsProblems();

        this.checkAdvContact();

        let in_activation: boolean = await this.checkActivation();
        if(!in_activation) this.checkFirstTrackerPosition();
        if(DataService.customerPhone && DataService.ownerPhone){
          /** Il cliente sta accedendo all'app dal link di login letto da whatsapp, chiamata api per associare il contatto  */
          try{
            console.log('Sono in associazione organization - ticket')
            await this.api.associateOrganizationCommunication(DataService.customerPhone, DataService.ownerPhone)
            //TODO: gestire il success mostrando un alert con "associazione avvenuta correttamente"
            this.navController.navigateRoot('ticket')
          }catch(err){
            console.error('Errore durante l\'associazione del ticket con org')
          }
        }
        if(AuthService.redirect){
          this.navController.navigateRoot(AuthService.redirect)
        }else{
          if(this.originalUrl.pathname !== '/change-password' && this.originalUrl.pathname !== '/free-trial' && this.originalUrl.pathname !== '/shop'){
            this.navController.navigateRoot('map');
          }else{
            this.navController.navigateRoot(this.originalUrl.pathname);
          }
        }

        if(!this.auth.isForced){
          try{
            if(this.data.selOrganization?.billing_type === 2){
              StatisticsService.sendLogEventGoogle('clienti_azienda'); // Invia evento se in attivazione
            }
          }catch(err){
            console.error(err);
          }
        }
      } else {
        const baseUrl: string = await this.storage.get('baseUrl');
        await this.api.storage.clear();
        if(baseUrl) await this.storage.set('baseUrl', baseUrl);
        MainMapService.clearService();
        this.data.clearData();
        this.data.isDemo = false;

        //se l'utente arriva da freetrial
        if(this.originalUrl.pathname !== '/free-trial'/* && this.originalUrl.pathname !== '/shop'*/){
          console.error('va a login');
          this.navController.navigateRoot('login');
        }else{
          this.navController.navigateRoot(this.originalUrl.pathname);
        }
      }

    }catch(err){
      console.error(err);
      if(err?.error?.type === 1){
        this.view.presentAlert("Attenzione", "Questo utente non è di questa piattaforma", [{
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            location.reload();
          }
        }]);
        await this.auth.logout();
        return;
      }
      if(AuthService.authTokenInUrl){
        console.error('Errore di login con un token scaduto o invalido', err);
        await this.auth.logout();
        return;
      }
    }
  }

  // Gestisce i vari errori possibile nel processo di avvio dell'app
  private manageStartUpError(err){
    console.error(err);
    // this.splashScreen.hide();
    this.translate.get('START_INFO').subscribe((lang: any) => {
      if(lang){
        this.view.presentAlert(lang.ops, lang.servizio_non_disponibile, [], undefined, undefined, false);
      }else{
        this.view.presentAlert('Error', 'Service temporary not available', [], undefined, undefined, false);
      }
    });
  }

  // Verifica se il progetto di firebase è quello di prod o di dev
  checkProdDevMod(){
    try{
      if(firebase.app().options.projectId === 'white-label-dev-635e0'){
        this.isDev = true;
      }else{
        this.isDev = false;
      }
    }catch(err){
      console.error(err);
    }
  }

  setPath(path: string){
    this.currentPath = path;
    switch (this.currentPath) {
      case 'login':
        this.menuVisible = false;
        break;
      default:
        this.menuVisible = true;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  hint_modal_opened: boolean;
  async checkPaymentsProblems(){
    if(!this.data.selOrganization.login_view_demo){
      if(this.data.selOrganization.login_blocked){
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'delinquent'
          },
          backdropDismiss: false,
          cssClass: "modal_alarm"
        });
        return await modal.present();
      } else if(!this.hint_modal_opened && this.data.selOrganization.login_need_default_payment_method){
        this.hint_modal_opened = true;
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'hint'
          },
          cssClass: "modal_alarm"
        });
        return await modal.present();
      }else if(!this.hint_modal_opened && this.data.selOrganization.login_stripe_invoices_unpaid_count > 0 && !this.data.selOrganization.stripe_disable_add_payment){
        this.hint_modal_opened = true;
        const modal = await this.modalController.create({
          component: PaymentProblemsPage,
          componentProps: {
            type: 'delinquent',
            dismissible: true
          },
          backdropDismiss: false,
          cssClass: "modal_alarm"
        });
        return await modal.present();
      }
    }
  }

  // Effettua l'api per associare ip o cookie ad una specifica adv a fini statistici
  async checkAdvContact(){
    if(this.isForcedAuth) return; //Non fa api di match contatti
    try{
      let cookie: string, ip: any;
      try{
        ip = await this.api.getIp();
        if(ip?.ip){
          ip = ip.ip;
        }else{
          ip = undefined;
        }
      }catch(err){
        console.error('Error reading ip', err);
      }
      try{
        const nameEQ = "cookieAdvBalin=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0){
            cookie = c.substring(nameEQ.length, c.length);
            break;
          }
        }

      }catch(err){
        console.error('Error reading cookie', err);
      }
      console.log(ip, cookie);
      if(ip || cookie){
        await this.api.postMatchContact(this.data.selOrganization.me._id, ip, cookie);
      }
    }catch(err){
      console.error('Error in match adv contact', err);
    }
  }

  // Se proveniamo da attivazione (imei e seriale in parametro url) apre il modal di attivazione
  async checkActivation(): Promise<boolean>{
    if(DataService.activationImei && DataService.activationSerial){
      const modal = await this.modalController.create({
        component: UserDataPage,
        componentProps: {
          fromActivation: true,
          appComponent: this
        },
        backdropDismiss: false,
        cssClass: "modal_alarm",
      });
      await modal.present();
      return true;
    }
    return false;
  }

  changeUrl(destination_url: string){
    let url: string = destination_url;
    // if(DataService.customerPhone && DataService.ownerPhone){
    //   url += `?customerPhone=${DataService.customerPhone.replace('+', '')}&ownerPhone=${DataService.ownerPhone.replace('+', '')}`;
    // }
    try{
      url = this.originalUrl.href.replace(this.originalUrl.origin, url); // Se avessi parametri in url sono gestiti
    }catch(error){
      console.error(error);
      url = destination_url;
    }
    window.location.href = url;
  }

  // Verifica se la dimensione dello schermo necessita di far vedere la versione desktop (SOLO PROD)
  private checkWidthVersion(){
    try{
      if(!DataService.nativeApp && environment.mobileUrl){
        const min_width: number = 1024;
        if(window.innerWidth < min_width){
          this.changeUrl(environment.mobileUrl);
        }else{
          window.onresize = async () => {
            if(this.flag_view_check) return;
            this.flag_view_check = true;
            try{
              if(window.innerWidth < min_width){
                if(!this.widthAlert){
                  const lang: any = this.translate.instant('DIMENSION_ALERT');
                  this.widthAlert = await this.alertController.create({
                    header: lang.titolo,
                    message: lang.messaggio,
                    backdropDismiss: false,
                    buttons: [
                      {
                        text: lang.risposta,
                        handler: () => {
                          this.changeUrl(environment.mobileUrl);
                        }
                      }
                    ]
                  });
                  await this.widthAlert.present();
                }
              }else{
                if(this.widthAlert){
                  await this.widthAlert.dismiss();
                  this.widthAlert = undefined;
                }
              }
            }catch(err){
              console.error(err);
            }
            this.flag_view_check = false;
          };
        }
      }
    }catch(err){
      console.error('Cambio url non attivo perchè in DEV mod, oppure per un altro errore.');
    }


    try{
      if(window.innerWidth < 1024 && environment.mobileUrl){
        this.changeUrl(environment.mobileUrl);
      }
    }catch(err){
      console.error('Cambio url non attivo perchè in DEV mod, oppure per un altro errore.');
    }
  }

  // Se abbiamo solo un tracker che è attivo ma senza posizioni apro in automatico il modal di informazioni
  async checkFirstTrackerPosition(){
    // let tracker: Partecipant;
    // for(const participant of this.data.selOrganization.participants){
    //   if(participant.isTracker()){
    //     if((!participant.virtualPosition || !participant.virtualPosition.havePosition) && participant.isActive){
    //       tracker = participant;
    //     } else{
    //       return;
    //     }
    //   }
    // }
    // if(tracker){
    //   const modal = await this.modalController.create({
    //     component: UserDataPage,
    //     componentProps: {
    //       tracker: tracker,
    //       appComponent: this.modalController
    //     },
    //     cssClass: "modal_alarm",
    //   });
    //   return await modal.present();
    // }
  }

  async checkPermission(functionalityType: any, closeModal?: any){
    try{
      if(!this.data.selOrganization.me.functionalityIsEnabled(functionalityType)){
        let navigationExtras: NavigationExtras = {
          queryParams: {
            functionality: functionalityType
          }
        };
        if(!closeModal){
          return await this.navController.navigateRoot('not-permitted', navigationExtras);
        }else{
          await closeModal();
          await this.navController.navigateRoot('not-permitted', navigationExtras);
        }
      }
    }catch(err){
      console.log('No yet data init');
    }
  }

  // * language
  setLang(args: { langCode?: string } = {}) {
    return new Promise((resolve, reject) => {
      let { langCode } = args;
      if (!langCode) {
        langCode = Utility.parseLanguageCode(this.translate.getBrowserLang() || navigator.language);
        switch (langCode) {
          case "it":
            langCode = "it";
            break;
          default:
            langCode = "en"
        }
        this.translate.use(langCode).subscribe(() => {
          console.log(`LINGUA CARICATA 1 --> ${this.translate.currentLang}`);
          return resolve(null);
        })
      } else {
        const found = DataService.generalConfig.languages.find(
          obj => obj.code === langCode
        );
        this.translate.use(found?.code ?? "en").subscribe(() => {
          console.log(`LINGUA CARICATA 2 --> ${this.translate.currentLang}`);
          return resolve(null);
        })
      }
    });
  }
}
