import { Component, OnInit } from '@angular/core';
import {ViewService} from "src/app/services/common/view.service";
import {DataService} from "src/app/services/common/data.service";
import {ApiService} from "src/app/services/common/api.service";
import {AuthService} from "src/app/services/common/auth.service";
import {PopoverController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ChangeDetectorRef} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {NavController} from "@ionic/angular";
import {ToastController} from "@ionic/angular";
import {Storage} from "@ionic/storage-angular";
import {Utility} from "src/app/classes/common/utility";
import {PopoverFlagComponent} from "src/app/components/desktop/popover-flag/popover-flag.component";
import {ModalController} from "@ionic/angular";
import {ElementRef} from "@angular/core";
import {ViewChild} from "@angular/core";
import {Input} from "@angular/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Input() closeModal :boolean;
  @Input() disableTitle: boolean;
  page: string = "login";
  lang: any;

  // listener
  listener = {
    view: null,
    lang: null,
    activation: null
  };

  username: string;
  usernameError: string;
  password: string;
  passwordError: string;
  confirmPassword: string;
  confirmPasswordError: string;
  organizationName: string;
  organizationNameError: string;
  acceptPrivacy: boolean;
  acceptPrivacyError: string;

  selectedLanguage: string; // in english
  selectedLanguageNotEn: string; // in "that" language
  selectedLanguageError: string;
  selectedTimezone: string;
  selectedTimezoneError: string;

  showPassword: boolean;

  activationToast: any;
  checkActivationToast: boolean;
  timeoutToast: any;


  @ViewChild('int') int: ElementRef;

  constructor(
    public view: ViewService,
    private api: ApiService,
    private auth: AuthService,
    private popoverCtrl: PopoverController,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private data: DataService,
    private navController: NavController,
    public toastController: ToastController,
    private modalCtrl: ModalController,
    private storage: Storage
  ) { }

  ngOnInit() {
    if(DataService.registrationEmail){
      this.username = DataService.registrationEmail
    }
    this.route.queryParams.subscribe(params => {
      if(params.page){
        this.page = params.page;
        ViewService.updateView.next(undefined);
      }
    });
  }


  ngAfterViewInit() {
    this.int.nativeElement.scrollIntoView({behavior: 'auto'});
    this.listenerView();

    //il setPath viene effettuato nel parent
    //this.appComponent.setPath('login');

    // Setta traduzione (in stream, perchè la pagina login viene caricata prima che la traduzione sia pronta e quindi avremmo dei problemi
    this.listener.lang = this.translate.stream('LOGIN').subscribe((lang: any) => {
      this.lang = lang;

      // Faccio il controllo per l'alert di attivazione altrimenti potrei non avere la lingua corretta nel toast che si apre
      if(DataService.activationImei && DataService.activationSerial){
        // Fa vedere un toast se si proviene dall'attivazione di un tracker.
        this.presentActivationInfo();
      }
    });

    // Setta la lingua dal browser se disponibile
    let userLang = Utility.parseLanguageCode(this.translate.getBrowserLang() || navigator.language);
    if(userLang){
      let l: any = DataService.generalConfig.languages.find((elem: any) => {
        return elem.code === userLang;
      });
      if(!l){
        l = {code: 'en'}
      }
      this.selectedLanguage = l.language;
      this.selectedLanguageNotEn = this.returnLangLbl(l.code);
      ViewService.updateView.next(undefined);
    }

  }

  async ngOnDestroy() {
    for(let listener in this.listener){
      if (this.listener[listener]) {
        this.listener[listener].unsubscribe();
      }
    }
    if(this.timeoutToast) clearTimeout(this.timeoutToast);
    if(this.activationToast){
      this.activationToast.dismiss();
    }
    //il set path viene effettuato nel parent
    //this.appComponent.setPath('');
  }

  // Effettua la verifica sul form
  async checkFormError(input: string, noTimeout?: boolean){
    if(!noTimeout) await Utility.wait(500);
    switch (input) {
      case 'username':
        console.log(this.username);
        if(!this.username){
          this.usernameError = this.lang.inserisci_email;
          break;
        }
        if(!Utility.validateEmail(this.username)){
          this.usernameError = this.lang.email_non_valida;
          break;
        }
        this.usernameError = undefined;
        break;
      case 'password':
        if(!this.password){
          this.passwordError = this.lang.no_password;
          break;
        }
        if(this.password.length < 8){
          this.passwordError = this.lang.password_lunghezza;
          break;
        }
        if(this.confirmPassword && this.password !== this.confirmPassword){
          this.confirmPasswordError = this.lang.password_non_coincidono;
          this.passwordError = this.confirmPasswordError;
          break;
        }
        if(this.confirmPasswordError === this.passwordError){
          this.confirmPasswordError = undefined;
        }
        this.passwordError = undefined;
        break;
      case 'confirmPassword':
        if(!this.confirmPassword){
          this.confirmPasswordError = this.lang.no_conferma_password;
          break;
        }
        if(this.password !== this.confirmPassword){
          this.confirmPasswordError = this.lang.password_non_coincidono;
          this.passwordError = this.confirmPasswordError;
          break;
        }
        if(this.passwordError === this.confirmPasswordError){
          this.passwordError = undefined;
        }
        this.confirmPasswordError = undefined;
        break;
      case 'acceptPrivacy':
        if(!this.acceptPrivacy){
          this.acceptPrivacyError = this.lang.no_accettazione_privacy;
          break;
        }
        this.acceptPrivacyError = undefined;
        break;
      case 'organizationName':
        if(!this.organizationName){
          this.organizationNameError = this.lang.no_organization_name;
          break;
        }
        this.organizationNameError = undefined;
        break;
      case 'language':
        if(!this.selectedLanguage){
          this.selectedLanguageError = this.lang.no_language;
          break;
        }
        this.selectedLanguageError = undefined;
        break;
      case 'timezone':
        if(!this.selectedTimezone){
          this.selectedTimezoneError = this.lang.no_timezone;
          break;
        }
        this.selectedTimezoneError = undefined;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  openInfoField(field: string){
    switch (field) {
      case 'organizationName':
        return this.view.modalAlert(this.lang.info, this.lang.info_organizzazione, 'info', this.lang.ok);
      case 'language':
        return this.view.modalAlert(this.lang.info, this.lang.info_language, 'info', this.lang.ok);
      case 'timezone':
        return this.view.modalAlert(this.lang.info, this.lang.info_timezone, 'info', this.lang.ok);
    }
  }

  async singnUp(){
    this.checkFormError('username', true);
    this.checkFormError('password', true);
    this.checkFormError('confirmPassword', true);
    this.checkFormError('language', true);
    this.checkFormError('acceptPrivacy', true);
    if(this.usernameError || this.passwordError || this.confirmPasswordError || this.selectedLanguageError || this.acceptPrivacyError){
      return;
    }
    this.username = this.username.trim();
    this.page = 'loading';
    ViewService.updateView.next(undefined);
    try{
      let lang: any = DataService.generalConfig.languages.find((elem: any) => {
        return elem.language === this.selectedLanguage;
      });
      if(lang){
        await this.storage.set('organization_language', lang.code);
      }
      await this.auth.createUserWithEmailAndPassword(this.username, this.password);
      this.showCongratulationsSignUp();
      AuthService.hasLogged.next(undefined)
    }catch(err){
      console.error(err);

      this.page = 'signUp';
      ViewService.updateView.next(undefined);
      this.parseFirebaseError(err);
    }
  }

  async login(){
    this.checkFormError('username', true);
    this.checkFormError('password', true);
    if(this.usernameError || this.passwordError || this.confirmPasswordError || this.acceptPrivacyError){
      return;
    }
    this.username = this.username.trim();
    this.page = 'loading';
    ViewService.updateView.next(undefined);
    try{
      await this.auth.loginWithEmailAndPassword(this.username, this.password);
      if(this.closeModal){
        this.modalCtrl.dismiss()
      }
      AuthService.hasLogged.next(undefined)

    }catch(err){
      console.error(err);
      this.page = 'login';
      ViewService.updateView.next(undefined);
      this.parseFirebaseError(err);
    }
  }

  // Gestisce gli errori provenienti da firebase
  parseFirebaseError(err: any){
    switch (err.code) {
      case 'auth/invalid-email':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.email_invalida, 'warn', this.lang.ok);
      case 'auth/user-disabled':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.utente_disabilitato, 'warn', this.lang.ok);
      case 'auth/user-not-found':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.utente_non_trovato, 'warn', this.lang.ok);
      case 'auth/wrong-password':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.password_errata, 'warn', this.lang.ok);
      case 'auth/email-already-in-use':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.email_in_uso, 'warn', this.lang.ok);
      case 'auth/weak-password':
        return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.password_non_abbastanza_complessa, 'warn', this.lang.ok);
    }
    return this.view.modalAlert(this.lang.errori.errore, this.lang.errori.autenticazione_fallita, 'warn', this.lang.ok);
  }

  // Effettua il recupero della password con un reset
  resetPassword(){
    let inputs: any[] = [
      {
        name: 'email',
        type: 'email',
        placeholder: this.lang.indirizzo_email_account
      }
    ];
    let buttons: any[] = [
      {
        text: this.lang.annulla,
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {}
      }, {
        text: this.lang.invia,
        handler: async (data: any) => {
          if(!data || !data.email){
            return this.view.modalAlert(this.lang.attenzione, this.lang.inserisci_email, 'warn', this.lang.ok);
          }
          if(!Utility.validateEmail(data.email)){
            return this.view.modalAlert(this.lang.attenzione, this.lang.email_non_valida, 'warn', this.lang.ok);
          }
          try{
            await this.view.showLoading(this.lang.attendere);
            await this.auth.resetPassword(data.email);
            this.view.modalAlert(this.lang.successo, this.lang.reset_password_info, 'succ', this.lang.ok);
          }catch(err){
            console.error(err);
            if(err.code === 'auth/user-not-found'){
              this.view.modalAlert(this.lang.errori.errore, this.lang.errori.utente_non_trovato + ": " + data.email, 'warn', this.lang.ok);
            }else{
              this.view.modalAlert(this.lang.errori.errore, this.lang.errori.reset_password, 'warn', this.lang.ok);
            }
          }
          this.view.hideLoading();
        }
      }
    ];
    this.view.presentAlert(this.lang.password_dimenticata, this.lang.recupera_password_info, buttons, undefined, undefined, true, inputs);
  }

  // Apre i popover di scelta multipla
  configAccountSelect(event: any, action: string) {
    switch (action) {
      case "timezones":
        this.popoverFlag_timezone(event);
        break;
      case "lang":
        this.popoverFlag_lang(event);
        break;
    }
  }

  returnLangLbl(langCode: string) {
    // console.log("returnLangLbl()");
    let langLbl: string;

    switch (langCode) {
      case "en":
        langLbl = "English";
        break;
      case "es":
        langLbl = "Español";
        break;
      case "fr":
        langLbl = "Français";
        break;
      case "it":
        langLbl = "Italiano";
        break;
    }

    return langLbl;
  }

  // Apre scelta lingua
  async popoverFlag_lang(event: any) {
    let options: any[] = [];
    for(let lang of DataService.generalConfig.languages){
      options.push({
        id: lang.code,
        lbl: this.returnLangLbl(lang.code),
        lblEn: lang.language,
        flag: lang.code,
        selected: this.selectedLanguage === lang.language,
      });
    }
    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: false
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.selectedLanguage = data.data.lblEn;
        this.selectedLanguageNotEn = data.data.lbl;
        let lang: any = DataService.generalConfig.languages.find((elem: any) => {
          return elem.language === this.selectedLanguage;
        });
        this.setLang({langCode: lang.code});
        ViewService.updateView.next(undefined);
      }
      this.checkFormError('language');
    });
    await popover.present();
  }

  // Apre scelta timezone
  async popoverFlag_timezone(event: any) {
    let options: any[] = [];
    for(let timezone of DataService.generalConfig.timezones){
      options.push({
        id: timezone,
        lbl: timezone,
        selected: this.selectedTimezone === timezone,
      });
    }
    const popover = await this.popoverCtrl.create({
      backdropDismiss: true,
      component: PopoverFlagComponent,
      componentProps: {
        opts: options,
        enableSearch: true
      },
      cssClass: "popover_listPlain",
      event: event,
      showBackdrop: false,
    });
    popover.onDidDismiss().then((data: any) => {
      if(data && data.data){
        this.selectedTimezone = data.data.lbl;
        ViewService.updateView.next(undefined);
      }
      this.checkFormError('timezone');
    });
    await popover.present();
  }


  // listener
  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.ref.markForCheck();
      setTimeout(() => {
        this.ref.markForCheck();
      }, 250);
    });
  }

  // Visualizza l'alert con le info se si è aperto il portale da attivazione trracker ed è necessario l'accesso al portale
  async presentActivationInfo() {
    try{
      if(this.checkActivationToast){
        if(this.timeoutToast) clearTimeout(this.timeoutToast);
        this.timeoutToast = setTimeout(() => {
          this.presentActivationInfo();
        }, 400);
        return;
      }
      this.checkActivationToast = true;
      if(this.activationToast) await this.activationToast.dismiss();
      this.activationToast = await this.toastController.create({
        message: this.lang.info_attivazione,
        position: 'top',
        color: 'main-strong_base',
        buttons: [
          {
            text: this.lang.ok,
            role: 'cancel'
          }
        ]
      });
      await this.activationToast.present();
      this.checkActivationToast = false;
    }catch(err){
      console.error(err);
    }
  }

  // Visualizza toast di congratulazioni dopo la registrazione
  async showCongratulationsSignUp(){
    //qua voglio scaturire un evento che mi faccia dire, ok l'utente si è registrato
    if(this.closeModal){
      this.modalCtrl.dismiss()
    }
    await this.view.modalAlert(this.lang.congratulazioni, this.lang.configurazione_salvata, 'succ', this.lang.ok);
  }

  // Apre condizioni d'uso
  openTerm(){
    try{
      window.open(DataService.generalConfig.sito.url_termini_condizioni, '_blank');
    }catch(err){
      console.error(err);
    }
  }

  // Apre informativa privacy
  openPrivacy(){
    try{
      window.open(DataService.generalConfig.sito.url_privacy, '_blank');
    }catch(err){
      console.error(err);
    }
  }

  setLang(args: { langCode?: string } = {}) {
    return new Promise((resolve, reject) => {
      let { langCode } = args;
      if (!langCode) {
        langCode = Utility.parseLanguageCode(this.translate.getBrowserLang() || navigator.language);
        switch (langCode) {
          case "it":
            langCode = "it";
            break;
          default:
            langCode = "en"
        }
        this.translate.use(langCode).subscribe(() => {
          console.log(`LINGUA CARICATA 1 --> ${this.translate.currentLang}`);
          return resolve(null);
        })
      } else {
        const found = DataService.generalConfig.languages.find(
          obj => obj.code === langCode
        );
        this.translate.use(found?.code ?? "en").subscribe(() => {
          console.log(`LINGUA CARICATA 2 --> ${this.translate.currentLang}`);
          return resolve(null);
        })
      }
    });
  }

}
