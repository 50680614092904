// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  height: 300px;
  display: flex;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/image-detail/image-detail.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,YAAA;AACF","sourcesContent":["img{\n  height: 300px;\n  display: flex;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
