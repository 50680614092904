// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details {
  width: 100%;
  align-self: baseline;
}
.details .items > *:not(:last-child) {
  margin-bottom: 1rem;
}
.details .items > *:last-child {
  margin-bottom: 0.5rem;
}

.summary > *:first-child {
  border-top: var(--border-divider);
}

.total {
  margin-top: 0.5rem;
  border-top: var(--border-divider);
}

.small {
  font-size: var(--font-secondary);
  color: var(--color-secondary);
}

.w30p {
  width: 30px;
}

.w40p {
  width: 40px;
}

.w50p {
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/invoice-cost-details/invoice-cost-details.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;AACE;EACD,mBAAA;AACD;AAEE;EACD,qBAAA;AAAD;;AAIA;EACE,iCAAA;AADF;;AAIA;EACE,kBAAA;EACA,iCAAA;AADF;;AAIA;EACE,gCAAA;EACA,6BAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAGA;EACE,WAAA;AAAF","sourcesContent":[".details{\n  width: 100%;\n  align-self: baseline;\n\n  .items > *:not(:last-child){\n\tmargin-bottom: 1rem;\n  }\n\n  .items > *:last-child{\n\tmargin-bottom: 0.5rem;\n  }\n}\n\n.summary > *:first-child{\n  border-top: var(--border-divider);\n}\n\n.total{\n  margin-top: 0.5rem;\n  border-top: var(--border-divider);\n}\n\n.small{\n  font-size: var(--font-secondary);\n  color: var(--color-secondary);\n}\n\n.w30p{\n  width: 30px;\n}\n\n.w40p{\n  width: 40px;\n}\n.w50p{\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
