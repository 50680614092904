import {firebaseConfig} from "./firebase/environment-firebase-dev";

export const environment = {
  app_name: "Balin Dev",
  production: false,

  version: "2.4.9",
  platform_type: 1,

  baseUrl: "https://default-dev-2jghk7fwgq-ew.a.run.app/",
  // baseUrl: "http://localhost:8084/",
  communicationBaseUrl: "https://comunication-dev-2jghk7fwgq-ew.a.run.app/",

  originalUrl: new URL(window.location.href),

  desktopUrl: "", //Da valorizzare solo in prod
  mobileUrl: "", //da valorizzare solo in prod


  baseUrl_nativeApp: "", // solo per app nativa prod
  communicationBaseUrl_nativeApp: "", // solo per app nativa prod

  firebaseConfig: firebaseConfig
};


// import {firebaseConfig} from "./firebase/environment-firebase-prod";
//
// export const environment = {
//   app_name: "Balin Dev",
//   production: true,
//   version: "2.4.8",
//   platform_type: 1,
//
//   baseUrl: "https://default-dev-2jghk7fwgq-ew.a.run.app/",
//   communicationBaseUrl: "https://api.balin.app/",
//   originalUrl: new URL(window.location.href),
//
//
//   baseUrl_nativeApp: "", // solo per app nativa prod
//   communicationBaseUrl_nativeApp: "", // solo per app nativa prod
//
//   mobileUrl: "",
//   desktopUrl: "", //Da valorizzare solo in prod
//
//   firebaseConfig: firebaseConfig
// };
