import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {Partecipant} from "../../../../classes/common/partecipant";
import {ViewService} from "../../../../services/common/view.service";
import {ApiService} from "../../../../services/common/api.service";
import {DataService} from "../../../../services/common/data.service";
import {Utility} from "../../../../classes/common/utility";

@Component({
  selector: 'app-car-fields',
  templateUrl: './car-fields.page.html',
  styleUrls: ['./car-fields.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarFieldsPage implements OnInit {

  @Input() participant: Partecipant;

  lang: any;

  name: string;
  nameError: string;
  label: number;
  labelError: string;
  odometer: number;
  odometerError: string;

  listener = {
    view: null
  };


  constructor(
    private cd: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private view: ViewService,
    private translate: TranslateService,
    private api: ApiService,
    private data: DataService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.listenerView();
    this.translate.get('CAR_FIELDS').subscribe((lang: any) => {
      this.lang = lang;
      if(this.participant){
        this.name = this.participant.name;
        this.label = this.participant.label_number;
        this.odometer = this.participant.$total_odometer_live > 0 ? Math.floor(this.participant.$total_odometer_live / 1000) : 0; // l'odometro del partecipant è in metri, lo converto in km
      }
      ViewService.updateView.next(undefined);
    });
  }

  ionViewWillLeave() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }

  async checkFormError(input: string){
    switch (input) {
      case 'name':
        if(!this.name){
          this.nameError = this.lang.nome_veicolo_placeholder;
          ViewService.updateView.next(undefined);
          return;
        }
        this.nameError = undefined;
        break;
      case 'label':
        if(this.label && (this.label <= 0 || this.label > 999)){
          this.labelError = this.lang.label_invalido;
          ViewService.updateView.next(undefined);
          return;
        }
        this.labelError = undefined;
        break;
      case 'odometer':
        if(this.odometer && this.odometer <= 0){
          this.odometerError = this.lang.odometro_invalido;
          ViewService.updateView.next(undefined);
          return;
        }
        this.odometerError = undefined;
        break;
    }
    ViewService.updateView.next(undefined);
  }

  async save(){
    this.checkFormError('name');
    this.checkFormError('label');
    this.checkFormError('odometer');
    let error: string = this.nameError || this.labelError || this.odometerError;
    if(error){
      return this.view.modalAlert(this.lang.attenzione, error, 'warn', this.lang.ok);
    }
    await this.view.showLoading();
    try{
      if(!this.participant.isDemo){
        //converto l'odometro da km a metri prima di passarlo all'api
        let odometer = undefined;
        if(this.odometer !== Math.floor(this.participant.$total_odometer_live / 1000) ){
          odometer = this.odometer * 1000;
        }

        const updated_partecipant = await this.api.patchParticipantCarsField(this.data.selOrganization.me._id, this.participant._id, this.name, this.label, odometer);
        if(updated_partecipant && odometer){
          this.participant.$total_odometer_live = odometer
        }
      }else{
        this.data.saveDemoFields(this.participant, this.name, this.label, this.odometer)
      }
      this.view.modalAlert(this.lang.successo, this.lang.salvataggio_completato, 'succ', this.lang.ok);
      this.view.hideLoading();
      this.dismiss();
    }catch(err){
      console.error(err);
      this.view.modalAlert(this.lang.errori.errore, this.lang.errori.impossibile_salvare, 'warn', this.lang.ok);
      this.view.hideLoading();
    }
  }

}
