import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  MenuController, ModalController,
  NavController,
} from '@ionic/angular';

import {TranslateService} from "@ngx-translate/core";
import {
  DataService
} from "../../../services/common/data.service";
import {
  ViewService
} from "../../../services/common/view.service";
import {
  AuthService
} from "../../../services/common/auth.service";
import {AppComponent} from "../../../app.component";
import {
  AccountSettingsPage
} from "../../../pages/desktop/common/account-settings/account-settings.page";
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {

  lang: any = {};

  actionButtons = {
    set1: [
      {
        action: () => {
          this.navCtrl.navigateForward('users');
          this.toggleSidebar();
        },
        label: "gestione_utenti",
        icon: "relation",
        menu: null,
      },
      {
        action: () => {
          this.navCtrl.navigateForward('cars');
          this.toggleSidebar();
        },
        label: "elenco_veicoli",
        icon: "traffic",
      },
      {
        action: () => {
          this.navCtrl.navigateForward('shop');
          this.toggleSidebar();
        },
        label: "acquista",
        icon: "cart",
      },
      {
        action: async () => {
          this.navCtrl.navigateForward('activation');
          this.toggleSidebar();
        },
        label: "attiva_localizzatori",
        icon: "addBtn",
      },
      {
        action: () => {
          this.navCtrl.navigateForward('referral');
          this.toggleSidebar();
        },
        label: "referral",
        icon: "gift-box",
      },
    ],

    set2: [
      {
        action: () => {
          this.navCtrl.navigateForward('payment');
          this.toggleSidebar();
        },
        label: "pagamenti_fatture",
        icon: "cards",
      },
      {
        action: () => {
          this.navCtrl.navigateForward('order-history');
          this.toggleSidebar();
        },
        label: "cronologia_ordini",
        icon: "shopping-bag",
      },
      {
        action: () => {
          this.navCtrl.navigateForward('tickets');
          this.toggleSidebar();
        },
        label: "supporto",
        icon: "help",
      },
      {
        action: () => {
          this.navCtrl.navigateForward('notifications');
          this.toggleSidebar();
        },
        label: "notifiche",
        icon: "bell",
      },
    ],

    set3: [
      {
        action: () => {
          console.log('action')
          if(AuthService.authTokenInUrl) this.storage.set('authToken', null).then(()=>{})
          this.auth.logout();
          this.toggleSidebar();
        },
        label: "logout",
        icon: "exit",
      },
    ],
  };

  // listener
  listener = {
    view: null
  };

  constructor(
    private menuCtrl: MenuController,
    private cd: ChangeDetectorRef,
    public view: ViewService,
    public data: DataService,
    private translate: TranslateService,
    private navCtrl: NavController,
    private auth: AuthService,
    public appComponent: AppComponent,
    private modalCtrl: ModalController,
    private storage: Storage,
  ) {
  }

  ngOnInit() {
    this.listenerView();
    ViewService.updateView.next(undefined);
    this.translate.get('SIDEBAR').subscribe((lang: any) => {
      this.lang = lang;
    });
  }

  ngOnDestroy() {
    if (this.listener.view) {
      this.listener.view.unsubscribe();
    }
  }

  async toggleSidebar() {
    await this.menuCtrl.toggle("sidebar");
  }

  /** listener **/

  listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      this.view.pipeChanged++;
      this.cd.markForCheck();
      setTimeout(() => {
        this.cd.markForCheck();
      }, 250);
    });
  }




  // modal

  async openAccountSettings(){
    const modal = await this.modalCtrl.create({
      component: AccountSettingsPage,
      cssClass: "modal_alarm",
    });
    await modal.present();
  }

  async openContactAdd() {
    // console.log("openContactAdd()")

    // const modal = await this.modalCtrl.create({
    //   component: ContactAddPage,
    //   /*componentProps: {
    //    navData: {}
    //    },*/
    //   // backdropDismiss: false,
    //   // showBackdrop: false,
    //   cssClass: "modal_list",
    // });


    // //noinspection TypeScriptUnresolvedFunction
    // await modal.present();


    // /*//noinspection TypeScriptUnresolvedFunction
    //  const { data } = await modal.onWillDismiss();
    //  // console.log(data);

    //  if (data) {}*/
  }

  async openPage_ChatGroupNew() {
    // console.log("openPage_ChatGroupNew()")

    // const modal = await this.modalCtrl.create({
    //   component: ChatGroupNewPage,
    //   /*componentProps: {
    //    navData: {}
    //    },*/
    //   // backdropDismiss: false,
    //   // showBackdrop: false,
    //   cssClass: "modal_ChatGroupNew",
    // });


    // //noinspection TypeScriptUnresolvedFunction
    // await modal.present();


    // /*//noinspection TypeScriptUnresolvedFunction
    //  const { data } = await modal.onWillDismiss();
    //  // console.log(data);

    //  if (data) {}*/
  }






}
